import React from 'react';


export function ContainerSection(props) {
  const colour = props.colour? props.colour: 'light';
  
  return (
    <div className="highlight-light-10">
    <div className={"p-2 mb-2 rounded  border highlight-"+colour+"-3"}>
      {props.children}
    </div>
    </div>
  );
}

export function ContainerHeader(props) {
  const colour = props.colour? props.colour: 'primary';
  return (
    <div className="bg-white">
    <div className={"column-scroll rounded px-1 mb-3 highlight-"+colour+"-1 border border-"+ colour}>
        {props.children}
    </div>
    </div>
  );
}

export function ContainerBreadcrumb (props){
  const colour = props.colour;

  const constructed_path = props.fieldPath.reduce(function(collector, path_item){ return collector + ": " + path_item + " " }, "")
  return (
    <ContainerHeader colour={colour}>
      <small className="text-black-6">{constructed_path}</small>
    </ContainerHeader>
  )

}
