import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveLine } from '@nivo/line';
// import { ResponsiveWaffle } from '@nivo/waffle';
import { Modal, ButtonToolbar, Button } from 'rsuite';

import {Rainbow} from 'utils/color';
import API, {handleError} from 'utils/xhr';

import SideNavLayout from 'component/page/side_nav';
import {DomainBadge} from 'component/reuse/displays';
import {LoaderRipple} from 'component/loader';
import {SectionTitle, ParaTitle} from 'component/reuse/title';
import { isDemoAccount, getUserId } from 'utils/authentication';

////////////////////////////////////////////////////////////////////////
// CLEANUP
// calander heatmap to show individual activities
// https://github.com/g1eb/reactjs-calendar-heatmap
////////////////////////////////////////////////////////////////////////


class Page extends React.Component {

  state = {
    analytics: null,
  }

  componentDidMount(){
    const url = '/analytics';

    API.get(url).then(response => {

      this.setState({
        analytics: response.data.data,
      });

    }, (error) => {
      handleError(error, this.props.history, window.location.pathname);
    });
  }

  render (){

    const analytics = this.state.analytics;

    const is_mobile = window.innerWidth < 640;

    return (
      <SideNavLayout id="analytics-page" eventKey="analytics"  >
        <SectionTitle title="Target Analytics" icon_class="far fa-chart-bar" />

        {(analytics === null)? (
          <LoaderRipple />
        ): (
          <React.Fragment>
            {is_mobile? (
              <div className="mb-5">

                {/* <div className="alert alert-info">For detailed analytics please visit Desktop web view!</div> */}

                <div className="m-0 p-1">
                  <RadarAnalytics radar={analytics.radar} />
                </div>

                <div className="m-0 p-1">
                    <DetailedAnalytics activities_analytics={analytics.activities_analytics} />
                  </div>
              </div>
            ):(
              <div className="">
                <div className="mb-2 row m-0 p-0">
                <div className="col m-0 p-0">
                    <RadarAnalytics radar={analytics.radar} />
                  </div>
                </div>
                <div className="my-2 row m-0 p-0">
                  <div className="col m-0 p-0">
                    <DetailedAnalytics activities_analytics={analytics.activities_analytics} />
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        
      </SideNavLayout>
    )
  }
}



function calculate_score_precentage(score){
  return Math.max(Math.min(parseInt((score.achieved / (score.max - score.min)) * 100), 100), 0);
}

class DomainFilterSection extends React.Component{
  render (){
    const domains = this.props.domains;
    const domain_map = this.props.domain_map;
    const selected_domains = this.props.selected_domains;
    const onDomainSelect = this.props.onDomainSelect;

    return (
      <div>
        
        <div className="btn-group" role="group" aria-label="Basic example">
          {domains.map(function(domain, idx){
            
            const style = {
              true: {color: domain.color, border: '1px solid ' + domain.color},
              false: {color: 'white', backgroundColor: domain.color},
            }[selected_domains[domain.xid] === undefined];

            return (
              <button key={domain.xid} type="button" data-domain_xid={domain.xid} style={style} onClick={onDomainSelect} className="rounded btn mr-2 ">
                {domain.domain}<sup className="ml-2">{domain_map[domain.domain]}</sup>
              </button>
            )
          })}          
        </div>

      </div>

    )
  }
}

class DetailedAnalytics extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      selected_domains: null,
    };
  }
  
  onDomainSelect(e){
    e.preventDefault();

    const state = this.state;
    if (state.selected_domains == null){
      state.selected_domains = {};
      // this.setState(state);
    }
    if (state.selected_domains[e.target.dataset.domain_xid]){
      delete state.selected_domains[e.target.dataset.domain_xid];
    } else {
      state.selected_domains[e.target.dataset.domain_xid] = true;
    }
    this.setState(state);

  }

  render(){
    const activities_analytics = this.props.activities_analytics || [];

    // const is_mobile = window.innerWidth < 640;

    if (activities_analytics.length === 0){
      return (
        <div className="my-3">
          We need more information and event cycles to be completed before we can show any meaningful data in here. Please check back later!
        </div>
      )
    }

    var color_percent = new Rainbow();
    var color_bg = new Rainbow();
    color_percent.setNumberRange(20, 85);
    color_bg.setNumberRange(0, 85);
    color_percent.setSpectrum('#FFFFFF', '#212529');
    color_bg.setSpectrum('#FFeeee', '#FFf9f9', '#FFFFFF');
    // color_bg.setSpectrum('#FF0000', '#FF7979', '#FFe0e0');
    
    var log_color_bg = new Rainbow();
    log_color_bg.setNumberRange(0, 100);
    log_color_bg.setSpectrum('#fff8f8', '#FFFeFe', '#FFFFFF');

    // const { Line } = Progress;

    const hide_completed = true;

    var domains = [];
    var domain_cnt_map = {};
    var selected_domains = {};
    activities_analytics.forEach(function(section_analytics, idx){
      section_analytics.items.forEach(function(section_analytic, idx){
        if (hide_completed && (section_analytic.template.status === 'paused')){return ;}

        if (domain_cnt_map[section_analytic.domain.domain]){
          domain_cnt_map[section_analytic.domain.domain] += 1;
        } else {
          domain_cnt_map[section_analytic.domain.domain] = 1;
          domains.push(section_analytic.domain);
          selected_domains[section_analytic.domain.xid] = true;
        }
        return;
      })
      return;
    })
    
    domains.sort(function(a, b){
      if (a.domain > b.domain){return 1;}
      return -1;
    })

    return (
      <div className="">
        
        <SectionTitle title="Detailed Analytics"  icon_class="fas fa-stream"  />

        <div className="text-center">
          <DomainFilterSection 
            domains={domains} 
            domain_map={domain_cnt_map} 
            selected_domains={this.state.selected_domains || selected_domains}
            onDomainSelect={this.onDomainSelect.bind(this)} 
          />
        </div>

        {activities_analytics.map(function(section_analytics, idx){

          section_analytics.items.sort(function(a, b){
            if (a.domain.domain > b.domain.domain){return 1;}
            return -1;
          })

          var domain_map = {};
          var domain_list = [];
          var total_count = 0;
          section_analytics.items.map(function(section_analytic, idx){
            if (domain_map[section_analytic.domain.domain] === undefined){
              domain_list.push(section_analytic.domain.domain)
              domain_map[section_analytic.domain.domain] = {
                domain: section_analytic.domain,
                count: 0
              }
            }
            domain_map[section_analytic.domain.domain].count = domain_map[section_analytic.domain.domain].count + 1;
            total_count = total_count + 1;
            return null;
          })

          domain_list.sort(function(a, b){
            if (a > b){return 1;}
            return -1;
          })

          var data = [];
          var colour_list = [];
          domain_list.forEach(function(domain_key, idx){
            const domain_entry = domain_map[domain_key];

            var color_data = new Rainbow();
            color_data.setSpectrum(domain_entry.domain.color, '#FFFFFF');

            data.push({
              "id": domain_entry.domain.domain,
              "label": domain_entry.domain.domain,
              "value": (domain_entry.count / total_count) * 100,
              "color": domain_entry.domain.color,
            })
            colour_list.push(color_data.colourAt(25));
            // return null;
          })

          return (
            <div className="border dark-highlight-1 my-4 p-1 " key={"_"+idx}>
              <div className="text-center">
              
              <ParaTitle>{section_analytics.title}</ParaTitle>

              </div>

              <div className="row flex-row flex-nowrap" style={{"overflowX": "auto"}}>              
                {section_analytics.items.map(function(item, idx){

                  if (hide_completed && (item.template.status === 'paused')){return null;}

                  if (this.state.selected_domains && this.state.selected_domains[item.domain.xid] === undefined){ return null; }

                  const score_percentage = calculate_score_precentage(item.score);
                  var score_text = 'Score';
                  if (score_percentage <= 30){
                    if (item.score.achieved === null){
                      score_text = '';
                    } else {
                      score_text = '❗❗❗ Severly behind';
                    }
                  } else if (score_percentage <= 65){
                    score_text = '🔺 Pick up the pace ';
                  } else if (score_percentage <= 85){
                    score_text = '🔸 Almost there!';
                  } else {
                    score_text = '🎉 You got this! ';
                  }
                  const card_style = { 
                    width: '160px',
                    // backgroundColor: ((score_percentage <= 75 & item.score.achieved !== null)? color_bg.colourAt(score_percentage): 'auto'),
                  };
                  const text_style = {
                    backgroundColor: ((score_percentage <= 100 & item.score.achieved !== null)? color_bg.colourAt(score_percentage): 'auto'),
                  }
        
                  var data = [
                    {"id": "reached", "color": "#25ADCB", "data": []},
                    {"id": "target", "color": "#ff5860", "data": []}
                  ]
              
                  for (var i=0; i<item.logs.length; i++){
                    const log = item.logs[i];
                    data[0].data.push({"x": log.slot, "y": log.reached})
                    data[1].data.push({"x": log.slot, "y": log.target})
                  }

                  var circle_color_50 = new Rainbow();
                  circle_color_50.setNumberRange(0, 100);
                  circle_color_50.setSpectrum('#ffffff', item.domain.color);

                  var circle_color = new Rainbow();
                  circle_color.setNumberRange(20, 85);
                  circle_color.setSpectrum(circle_color_50.colourAt(50), item.domain.color);

                  return (
                    <div className="border white-bg-1 rounded m-1" key={"_"+idx} >
                      <div className="text-center  p-1 " style={card_style}>

                        <div className="d-flex w-100 justify-content-between p-1 " style={text_style} >
                          <div className="black-text-6 text-size-2 m-0 p-0 py-0 pb-1" > 
                            <small className="">{score_text}</small>
                          </div>
                          
                          <div className="text-right">
                            <DomainBadge domain={item.domain} />
                          </div>
                        </div>

                        <div className="d-flex w-100 justify-content-between "  >
                          <Link to={"/view/" + item.template.xid}  className="text-left black-text-3">
                            <h5 className={"text-size-3  mt-2 mb-1 mx-2"}>{item.title}</h5>
                          </Link>

                          {/* <Link to={"/widgets/" + getUserId() + "/analytics/" + item.template.xid} className="btn text-left black-text-3">
                            <i className="fa fa-share-alt "></i>
                          </Link> */}
                        </div>


                          {(item.score.achieved === null)? (
                          <div className="p-2"  >
                            <small className="text-muted">
                              Needs more completed cyles to generate score
                            </small>
                          </div>
                          ): (
                          <React.Fragment>
                            <div className=" "  >
                              <div className="">
                                {/* <Line 
                                  showInfo={(score_percentage < 100)}
                                  percent={score_percentage} 
                                  strokeColor={circle_color.colourAt(score_percentage)}
                                /> */}

                                <div className="p-2 d-flex w-100 justify-content-between align-items-center black-text-6">
                                  <div className="progress " style={{height: '5px', width: '70%', backgroundColor: '#ddd'}}>
                                    <div className={"dark-bg-4 progress-bar "} role="progressbar" 
                                      style={{
                                        width: score_percentage+'%', 
                                      }} 
                                      ></div>
                                  </div>

                                  <div className="">
                                  {score_percentage+'%'}
                                  </div>
                                </div>
                              </div>
                                
                            </div>

                            {(isDemoAccount())? (
                              <div className="my-2 ">
                                <BasicDemo item={item} data={data}/>
                              </div>
                            ): ('')}

                          </React.Fragment>
                          )}

                        <div className="mx-2 px-2">
                          <h5 className="">History</h5>
                          {item.logs.map(function(log, idx){
                            const score = ((log.reached / log.target) * 100).toFixed(0);
                            return(
                              <div 
                                className="small" key={"_"+idx} 
                                style={{backgroundColor: (score <100)? log_color_bg.colourAt(score): ''}}
                              >
                                <div className="smalla">
                                  <div className="small family-code text-black d-flex w-100 justify-content-between">
                                    <span className="">{log.slot}</span>
                                    <span className="" >
                                      {(score >= 100)? (<span className="text-success">✔</span>): ((score === 0)? (<span className="text-danger">X</span>):score+"%")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                      </div>
                    </div>
                  )

                  
                }, this)}
              </div>

            </div>
          )
        }, this)}
      </div>
    )

  }
}



class BasicDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open(e) {
    e.preventDefault();
    this.setState({ show: true });
  }
  render() {
    const item = this.props.item;

    return (
      <div className="modal-container">
        <ButtonToolbar>
          <a 
            href="/" 
            className="text-center" 
            style={{color: item.domain.color}} 
            onClick={this.open} 
          >Details</a>
        </ButtonToolbar>

        <Modal full backdrop={true} show={this.state.show} onHide={this.close}>
          <Modal.Header>
            <Modal.Title>{item.title}</Modal.Title>
            <DomainBadge domain={item.domain} />
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className=""style={{ height: "400px", width: "100%", "overflowX": "auto"}}>
              <ResponsiveLine
                  data={this.props.data}
                  margin={{ top: 50, right: 110, bottom: 80, left: 60 }}
                  colors={['#25ADCB', '#ff5860']}
                  curve="cardinal"
                  // curve="basis"
                  xScale={{ type: 'point' }}
                  // yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
                  // axisTop={null}
                  // axisRight={null}
                  axisBottom={{orient: 'bottom', tickSize: 5, tickPadding: 5, tickRotation: 45,}}
                  // axisLeft={{orient: 'left', tickSize: 5, tickPadding: 2, tickRotation: 0,}}
                  // pointSize={5}
                  // pointBorderWidth={2}
                  useMesh={true}
                  legends={[{
                    anchor: 'bottom-right',
                    direction: 'column',
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    symbolSize: 6,
                    symbolShape: 'circle',
                  }]}
              />
    
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} appearance="primary">
              Ok
            </Button>
            {/* <Button onClick={this.close} appearance="subtle">
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}



class RadarAnalytics extends React.Component{
  render(){
    const radar = this.props.radar || [];

    if (radar.length === 0){
      return ('')
    }

    const inverse_radar = [];
    for (var r = 0; r < radar.length; r ++){
      var item = radar[r];

      inverse_radar.push({
        "domain": item.domain,
        'score': 100 - item.score,
      })
    }

    return(
      <div className="border dark-bg-1 p-1 bg-light">
        <div className="text-center">
          <ParaTitle>Summary</ParaTitle>
        </div>

        <div className="row">

          <div className="col-md-6 m-0 p-2 rounded">
            
            <div className="p-3 m-3 text-center" style={{ height: 300 }}>
              <h5 className="text-center">Current state</h5>
              <ResponsiveRadar
                data={radar}
                keys={['avg_score', 'score', ]}
                indexBy="domain"
                maxValue={100}
                margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                curve="linearClosed"
                borderWidth={2}
                borderColor={{ from: 'color' }}
                gridLevels={5}
                gridShape="circular"
                gridLabelOffset={36}
                enableDots={true}
                dotSize={10}
                dotColor={{ theme: 'background' }}
                dotBorderWidth={2}
                dotBorderColor={{ from: 'color' }}
                enableDotLabel={true}
                dotLabel="value"
                dotLabelYOffset={-12}
                colors={['#dddddd', '#25ADCB', ]}
                fillOpacity={0.25}
                blendMode="multiply"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={true}
              />
            </div>

            <div className="my-2 text-center">
              <Link to={"/widgets/" + getUserId() + "/radar_summary"} className="btn btn-primary mr-2 ">
                <i className="fas fa-share-alt mr-2"></i>Share
              </Link>
            </div>
            
          </div>

          <div className="col-md-6 m-0 p-2 rounded">
            <div className="p-3 m-3" style={{ height: 300 }}>
              <h5 className="text-center">Needs improvement</h5>
              <ResponsiveRadar
                data={inverse_radar}
                keys={[ 'score' ]}
                indexBy="domain"
                maxValue={100}
                margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                curve="linearClosed"
                borderWidth={2}
                borderColor={{ from: 'color' }}
                gridLevels={5}
                gridShape="circular"
                gridLabelOffset={36}
                enableDots={true}
                dotSize={10}
                dotColor={{ theme: 'background' }}
                dotBorderWidth={2}
                dotBorderColor={{ from: 'color' }}
                enableDotLabel={true}
                dotLabel="value"
                dotLabelYOffset={-12}
                colors={['#ff5860']}
                fillOpacity={0.25}
                blendMode="multiply"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={true}
              />
            </div>
          </div>

        </div>

      </div>
    )
  }
}




export default withRouter(Page);
