import React from 'react';
import { withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";
// import { ResponsiveRadar } from '@nivo/radar';

// import BaseWidgetLayout from 'component/page/blank';
import API, {handleError} from 'utils/xhr';
// import { getUserId } from 'utils/authentication';
// import Track from 'utils/tracker';
import BaseWidgetLayout from './base';


class Page extends React.Component {
  constructor(props){
    super(props);
    const {user_xid, template_xid} = this.props.match.params;

    this.state = {
      user_xid: user_xid,
      template_xid: template_xid,
    }
  }

  componentDidMount(){

    const url = '/user/' + this.state.user_xid + '/analytics/templates/' + this.state.template_xid;

    API.get(url).then(response => {
      console.log(response.data);
      this.setState({
        // radar: response.data.data.radar,
        user_info: response.data.data.user_info,
      });

    }, (error) => {
      handleError(error, this.props.history, window.location.pathname);
    });
  }

  render (){
    const user_info = this.state.user_info;
    if (user_info === undefined){
      return ''
    }

    console.log('Under construction');

    return (
      <BaseWidgetLayout id="analytics-details-page" title={user_info.name + "'s activity analytics"}>
        
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@bytebeacon" />
          <meta name="twitter:title" content="My activity progress" />
          <meta name="twitter:description" content="" />
          <meta name="twitter:creator" content="@bytebeacon" />
          <meta name="twitter:image" content="https://via.placeholder.com/250" />
          <meta name="twitter:domain" content="bytetrack.bytebeacon.com" />
        </Helmet>


        <div className="" >
        </div>


      </BaseWidgetLayout>
    )
  }
}

export default withRouter(Page);
