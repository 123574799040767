import React from 'react';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
// import {Toggle} from 'rsuite';
import API, {handleError} from 'utils/xhr';
import {Rainbow} from 'utils/color';

import {DomainBadge} from 'component/reuse/displays';
import { isDemoAccount, getUserId } from 'utils/authentication';


export default class Card extends React.Component{
  state={
    status: null,
  }

  onStatusChange(template_xid, status){
    const url = '/habits/' + template_xid + '/status';
    const data = {
      'status': status,
    }
    API.post(url, data).then(response =>{
      console.log(response.data.data);
      const state = this.state;
      state.status = status;
      this.setState(state)
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
}

  render(){
    const item = this.props.item.template;
    const stats = this.props.item.stats;
    const status = this.state.status || item.status;

    const card_style = {
      minWidth: '200px',
    };

    var complete_bg = {};
    if (status === 'paused'){
      var color_bg = new Rainbow();
      color_bg.setNumberRange(0, 100);
      color_bg.setSpectrum(item.domain.color, '#ffffff'); 

      // complete_bg = {backgroundColor: color_bg.colourAt(85),}
    }
  
    
    return (
      <div className={" m-1 p-0"} style={card_style}>
        <ReactTooltip effect="solid"/>

        <div className={"list-group "}>
          <div className={"list-group-item flex-column align-items-start m-0 p-1 " + ((status === 'paused')? ' light-highlight-3 border ': ' ')}  style={complete_bg}>
            
            <div className="px-2">

              <div className="px-1 pt-1">
                <div className="d-flex w-100 justify-content-between ">
                  {(status === 'paused')? (<div className="badge badge-danger">Paused</div>):(<div className=""></div>)}
                  <div className="text-right  ">
                    <DomainBadge domain={item.domain} />
                  </div>
                </div>

                <Link to={"/view/" + item.xid} className="text-black">
                  <h5 className={"text-size-3 text-left  mt-1 mb-2"}>{item.title}</h5>
                </Link>

                
              </div>

              <hr className="my-2" />

              <small className=" text-center d-flex w-100 justify-content-between ">
                
                <div className="text-black">
                  <div className="text-size-1 mx-2">
                    <i className="fa fa-sync"></i>
                  </div>
                  <div className="text-size-3">
                    {item.count.target}
                  </div>
                  <div className="text-size-1 mx-2">
                    {" " + ((item.count.target === 1)? item.count.unit.singular: item.count.unit.plural) + " " + (item.frequency.name)}
                  </div>
                </div>


                <div className="text-muted ">
                  <div className="text-size-1 mx-2">
                    <i className="fa fa-th"></i>
                  </div>
                  <div className="text-size-3">
                    {stats.completed } 
                  </div>
                  <div className="text-size-1 mx-2">
                    cycles completed
                  </div>
                </div>

                <div className="text-muted ">
                  <div className="text-size-1 mx-2">
                    <i className="fa fa-pencil-alt"></i>
                  </div>
                  <div className="text-size-3">
                    {stats.logged_count} 
                  </div>
                  <div className="text-size-1 mx-2">
                    entries logged
                  </div>
                </div>
              </small>
            </div>

            <div className="d-flex w-100 justify-content-between mt-3">

              <span className="mx-0">
                {(isDemoAccount())? (
                  <Link to={"/edit/" + item.xid} className="btn btn-outline-info btn-rounded" data-tip="Modify activity" data-type="info">
                    <i className="fa fa-pen"></i>
                  </Link>
                ):(
                  <Link 
                    to={"/view/" + item.xid} 
                    className={"btn btn-rounded  text-black " + ((status === 'active')? '  ': '  ')} 
                    // style={{color: item.domain.color, borderColor: item.domain.color}} 
                    data-tip="Configure details" 
                    data-type="dark"
                  >
                    <i className="fa fa-list "></i>
                  </Link>
                )}
              </span>

              <span className="mx-0">
                {/* <Link to={"/widgets/" + getUserId() + "/analytics/" + item.xid} className="btn text-left text-black">
                  <i className="fa fa-share-alt "></i>
                </Link> */}
              </span>

              <span className="mx-0">
                {(status === 'active')? (
                  <button onClick={this.onStatusChange.bind(this, item.xid, "paused")} type="button" className="btn text-danger  btn-rounded" data-tip="Pause tracking" data-type="error">
                    <i className="fa fa-pause"></i>
                  </button>
                ):(
                  <button onClick={this.onStatusChange.bind(this, item.xid, "active")} type="button" className="btn text-success btn-rounded" data-tip="Resume tracking" data-type="dark">
                    <i className="fa fa-play"></i>
                  </button>
                )}
              </span>
            
            </div>

            {/* {(item.logs.length === 0)? '': (
              <div className="row dark-bt-1-1 ">
                <div className="p-2 ">
                  <small>
                      {item.logs.map(function(log, idx){
                        return (
                          <div key={idx} className="py-1 m-0"><i className="fas fa-caret-right mr-2"></i>
                          {(log.href)? (
                            <small className=""><a href={log.href} className="">{log.text}</a></small>
                          ): (
                            <small className="">{log.text}</small>
                          )}
                          </div>
                        )
                      })}
                  </small>
                </div>
              </div>
            )} */}

          </div>
        </div>
      </div>
    )
  }
}
