export const unclassified_domain = {
  text: "",
  // color: "#333333",
}

export const lifestyle_domain = {
  text: "lifestyle",
  color: "#6c5ce7",
}

export const health_domain = {
  text: "health",
  color: "#e17055",
}

export const entrepreneurship_domain = {
  text: "entrepreneurship",
  color: "#FF4136",
}

export const relationship_domain = {
  text: "relationship",
  color: "#e84393",
}

export const empathy_domain = {
  text: "empathy",
  color: "#39CCCC",
}


/*
health
lifestyle
wealth
empathy

skill development
knowledge

spirituality
relationship

career

// color: "#FFDC00",
// color: "#e17055",
// color: "#0074D9",
// color: "#39CCCC",
// color: "#3D9970",
// color: "#6c5ce7",
// color: "#dcbf00",

/**/

/*
#FFDC00
#FF851B
#FF4136
#85144b
#F012BE
#B10DC9
#111111
#AAAAAA
#DDDDDD

#55efc4
#81ecec
#74b9ff
#a29bfe
#6c5ce7
#0984e3
#00cec9
#00b894
#ffeaa7
#fab1a0
#ff7675
#fd79a8
#e84393
#d63031
#e17055
#fdcb6e
#001f3f
#0074D9
#7FDBFF
#39CCCC
#3D9970
#2ECC40

/**/