import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import { Notification } from 'rsuite';

import {LoaderRipple} from 'component/loader';
import ActiveCard from 'component/reuse/card/active';

import SideNavLayout from 'component/page/side_nav';

import {SectionTitle} from 'component/reuse/title';
import {ActivityViewer} from 'component/reuse/activity_viewer';
import API, {handleError} from 'utils/xhr';


class Page extends React.Component {
  _isMounted = false;

  constructor(props){
    super(props);
    const {template_xid} = this.props.match.params;
    this.state = {
      template_xid: template_xid || null,
      template: null,
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    if (this.state.template_xid === null){
      return;
    }

    const url = '/habits/' + this.state.template_xid;    
    
    API.get(url).then(response => {
      if (this._isMounted) {
        const state = this.state;
        state.template = response.data.data.template;
        this.setState(state);
      }
      
    }, (error) => {
      handleError(error);handleError(error, this.props.history, window.location.pathname);
    });
  }

  onDelete(){
    if (window.confirm("Are you sure you wish to delete this target and all logs you've saved for this target?")){
      const url = '/habits/' + this.state.template_xid;
      API.delete(url).then(response =>{
        Notification['success']({
          title: 'Target deleted!',
          description: (<div className="">All data successfully deleted!</div>)
        });
        this.props.history.push('/');
      },(error) =>{
        handleError(error, this.props.history, window.location.pathname);
      })
    }
  }

  onStatusChange(template_xid, status){
    const url = '/habits/' + template_xid + '/status';
    const data = {
      'status': status,
    }
    API.post(url, data).then(response =>{
      // console.log(response.data.data);
      const state = this.state;
      state.template.status = status;
      this.setState(state)
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }


  render (){

    const template_xid = this.state.template_xid;
    const template = this.state.template;

    if (template ===null){
      return '';
    }

    const status = template.status;

    return (
      <SideNavLayout id="edit-activity-page">
        <SectionTitle title="Target details"  icon_class="fa fa-eye" />

        <ActivityViewer template={template}/>

        <div className="my-2">
          <Link to={"/edit/" + template_xid} className="my-1 btn btn-info text-white btn-rounded" data-tip="Modify activity" data-type="info">
            <i className="fa fa-pen mr-2"></i> Modify activity
          </Link>
        </div>


        <hr className="my-4"/>

        <div className="mb-5">
          <h4 className="my-2">Control</h4>

          <div className="my-2">
            {(status === 'active')? (
              <button onClick={this.onStatusChange.bind(this, template_xid, "paused")} type="button" className="my-1 btn btn-outline-danger btn-rounded">
                <i className="fa fa-pause mr-2"></i> Pause Tracking
              </button>
            ):(
              <button onClick={this.onStatusChange.bind(this, template_xid, "active")} type="button" className="my-1 btn btn-success btn-rounded">
                <i className="fa fa-play mr-2"></i> Resume Tracking
              </button>
            )}
          </div>
        </div>

        <div className="mb-5">
          <h5 className="my-2 text-size-8 text-danger">Sensitive area!</h5>
          <div className="my-2">Once you delete your target, the data will not be recoverable. Please be certain.</div>
          <div className="my-2">
            <button onClick={this.onDelete.bind(this)} className="btn btn-outline-danger">
              <i className="fa fa-trash mr-2"></i>
              Delete this target and all recorded data
            </button>
          </div>
        </div>

        <hr className="my-4"/>
        
        <HistoryComponent template_xid={template_xid} />

      </SideNavLayout>
    )
  }
}

class HistoryComponent extends React.Component {
  _isMounted = false;
  state = {
    loading: false,
    history: [],
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    if (this.props.template_xid === null){
      return;
    }

    const state = this.state;
    state.loading = true;
    this.setState(state);

    const url = '/habits/' + this.props.template_xid + '/history';

    API.get(url).then(response => {
      if (this._isMounted) {
        const state = this.state;
        state.history = response.data.data;
        state.loading = false;
        this.setState(state);
      }

    }, (error) => {
      handleError(error);handleError(error, this.props.history, window.location.pathname);
    });
  }

  render(){
    return(
      <div className="">
        <h4 className="my-2">History</h4>
        {(this.state.loading)? (<LoaderRipple />):(
          <div className='row'>
            {this.state.history.map(function(instance, idx){
              return(
                <div key={"_" + idx} className="col-md-3 col-sm-6 m-0 p-0 ">
                  <ActiveCard  active={idx === 0} item={instance} viewOnly />
                </div>
              )
            })}
          </div>
        )}

      </div>
    )
  }
}

export default withRouter(Page);
