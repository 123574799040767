import React from 'react';


export default function PageBlankLayout(props) {
  return (
    <div id={props.id} className="page-wrapper">
      {props.children}
    </div>
  );
}
