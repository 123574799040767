import React from 'react';
import { withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { ResponsiveRadar } from '@nivo/radar';

// import BaseWidgetLayout from 'component/page/blank';
import API, {handleError} from 'utils/xhr';
// import { getUserId } from 'utils/authentication';
// import Track from 'utils/tracker';
import BaseWidgetLayout from './base';


class Page extends React.Component {
  constructor(props){
    super(props);
    const {user_xid} = this.props.match.params;

    this.state = {
      user_xid: user_xid,
      radar: null,
      user_info: null,
    }
  }

  componentDidMount(){
    const url = '/widgets/' + this.state.user_xid + '/radar_summary';

    API.get(url).then(response => {
      this.setState({
        radar: response.data.data.radar,
        user_info: response.data.data.user_info,
      });

    }, (error) => {
      handleError(error, this.props.history, window.location.pathname);
    });
  }

  render (){

    const user_info = this.state.user_info;

    const radar = this.state.radar;
    if (radar === undefined || radar === null){
      return ''
    }

    // var result = radar.reduce(function(r, obj){
    //   var key = (obj.score >= 75)? 'good': ((obj.score < 35)? 'bad': 'ok');
    //   if (r[key] || (r[key]=[])) r[key].push(obj);
    //   return r;
    // }, {});  
    // console.log(result);

    return (
      <BaseWidgetLayout id="summary-radar-page" title={user_info.name + "'s radial report"}>
        
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@bytebeacon" />
          <meta name="twitter:title" content="My activity progress on a radial chart" />
          <meta name="twitter:description" content="" />
          <meta name="twitter:creator" content="@bytebeacon" />
          <meta name="twitter:image" content="https://via.placeholder.com/250" />
          <meta name="twitter:domain" content="bytetrack.bytebeacon.com" />
        </Helmet>

        <div className="" style={{ height: 300 }}>
          <ResponsiveRadar
            data={this.state.radar || []}
            keys={['avg_score', 'score', ]}
            indexBy="domain"
            maxValue={100}
            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
            curve="linearClosed"
            borderWidth={2}
            borderColor={{ from: 'color' }}
            gridLevels={5}
            gridShape="circular"
            gridLabelOffset={36}
            enableDots={true}
            dotSize={10}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={2}
            dotBorderColor={{ from: 'color' }}
            enableDotLabel={true}
            dotLabel="value"
            dotLabelYOffset={-12}
            colors={['#dddddd', '#25ADCB', ]}
            fillOpacity={0.25}
            blendMode="multiply"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={true}
          />
        </div>

      </BaseWidgetLayout>
    )
  }
}


export default withRouter(Page);
