import React from 'react';



export function LoaderRipple(props){
  if (props.fetching_data || true){
    return (<img src="/static/images/loaders/loader_ripple.svg" alt="Loader"/>)
  }
  return (<div className=""></div>)
}
