import React from 'react';
import { withRouter} from 'react-router-dom';

import SideNavLayout from 'component/page/side_nav';

import {SectionTitle} from 'component/reuse/title';
import {ActivityBuilder} from 'component/reuse/activity_builder';


class Page extends React.Component {
  constructor(props){
    super(props);
    const {template_xid} = this.props.match.params;

    this.state = {
      template_xid: template_xid,
    }
  }

  render (){

    return (
      <SideNavLayout id="edit-activity-page">
        <SectionTitle title="Edit target"  icon_class="fa fa-pen"  />

        <ActivityBuilder template_xid={this.state.template_xid}/>

      </SideNavLayout>
    )
  }
}

export default withRouter(Page);
