import React from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import SideNavLayout from 'component/page/side_nav';
import {LoaderRipple} from 'component/loader';
import {saveToken, saveLoginMeta, clearLoginMeta } from 'utils/authentication';

import {GeneralAPI,handleError} from 'utils/xhr';


class Page extends React.Component {
  render (){
    return (
      <SideNavLayout id="ghost-page">
        <Ghosting />
      </SideNavLayout>
    )
  }
}

class Ghosting extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      ghost_user_xid: '',
      ghosting_allowed: null,
      user_list: [],
      // grouped_user_list: {},
    }
  }

  componentDidMount(){
    const url = '/users/ghost';
    GeneralAPI.get(url).then(response =>{
      const state = this.state;
      state.ghosting_allowed = response.data.data.ghosting_allowed;
      state.user_list = response.data.data.users;
      // state.grouped_user_list = response.data.data.grouped_user_list || {};
      this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onChange(e){
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  ghostUser(e) {
    e.preventDefault();

    if (this.state.ghost_user_xid === ''){
      alert("ghost_user_xid cannot be empty!")
      return;
    }

    const url = '/users/ghost';
    var data = {
      ghost_user_xid: this.state.ghost_user_xid,
    }

    GeneralAPI.post(url, data).then(response => {

      const success = response.data.meta.success;
      if (success){
        clearLoginMeta();
        var jwt_token = response.data.data.token;
        saveToken(jwt_token);
        saveLoginMeta(response.data.data.meta);

        // const user_xid = response.data.data.meta.user_xid;
        window.location = '/';

      } else {
        alert(response.data.data.message);
      }

    }, (error) => {
      handleError(error);
    }, this);
  }

  render (){

    if (this.state.ghosting_allowed === null){
      return (<LoaderRipple fetching_data={true} />)
    }

    if (this.state.ghosting_allowed === false){
      window.location = '/';
    }

    return (
      <div className="container">

        <div className="m-3 row">
          <form className="col-xl-6">
          <fieldset className="form-group">

            <span className=" text-dark">Ghost User XID:</span>
            <input 
              className="form-control " 
              name="ghost_user_xid" 
              value={this.state.ghost_user_xid} 
              onChange={this.onChange.bind(this)} />

          </fieldset>
            <button 
              onClick={this.ghostUser.bind(this)} 
              className={"btn btn-rounded btn-danger"}>
                <i className="fa fa-ghost mr-2"></i>Ghost
            </button>

          </form>
        </div>
        <hr/>
        <div className="m-3 container">
          <h3 className="my-4">User List:</h3>
          <table className="table">
            <thead className="">
              <tr>
                <th scope="col">XID</th>
                <th scope="col">EMail</th>
                <th scope="col">Last login</th>
                <th scope="col">inserted at</th>
                <th scope="col">interactions</th>
              </tr>
            </thead>
            <tbody className="">
              {this.state.user_list.map(function(user, i){
                return (
                  <tr className=" family-code" key={"k_" + i} >
                    <th className="">{ user.xid }</th>
                    <td className="">{ user.email }</td>
                    <td className="">{ (user.interactions && user.interactions.data['last:login'])? (<Moment fromNow>{user.interactions.data['last:login']}</Moment>): ('') }</td>
                    <td className=""><Moment fromNow>{user.inserted_at}</Moment></td>
                    <td className="">
                      {(user.interactions)? (
                        <pre>
                          {Object.keys(user.interactions.data).map(function(key, i){
                            return (<div key={"_"+i}>{key} - <Moment fromNow>{user.interactions.data[key]}</Moment></div>)
                          })}
                        </pre>
                      ): ''}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          
        </div>

      </div>
    )
  }
}

export default withRouter(Page);