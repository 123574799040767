import React from 'react';
import { withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";

import PageBlankLayout from 'component/page/blank';
import { getUserId } from 'utils/authentication';
import Track from 'utils/tracker';


class Page extends React.Component {
    constructor(props){
        super(props);
        const {user_xid} = this.props.match.params;
    
        this.state = {
          user_xid: user_xid,
        }
      }
    
  shareClickTW(){
    this.shareClick('TW');
  }

  shareClickFB(){
    this.shareClick('FB');
  }

  shareClick(service){
    console.log("Social share click: " + service);
    Track.event("Social share click: " + service);
  }

  render (){
    const current_user = getUserId();

    return (
      <PageBlankLayout id={this.props.id}>
        
        <div className="p-3 m-3 text-center ">
            <h5 className="text-center">{this.props.title}</h5>
        
            {this.props.children}
        </div>

        {(current_user === this.state.user_xid)? (
            <React.Fragment>
          <div className="row text-center mb-2">
            <Helmet>
              <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
              <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0&appId=528203444310568&autoLogAppEvents=1" nonce="e7tcGl6x"></script>
            </Helmet>

            <div className="col"></div>
            <div className="col">
              <a onClick={this.shareClickTW.bind(this)} href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-size="large" data-text="Checkout my tracking stats!" data-via="bytebeacon" data-hashtags="bytetrack" data-show-count="false">Tweet</a>
            </div>
            <div className="col">
              <div className="fb-share-button" data-href={window.location.href} data-layout="button" data-size="large">
                <a onClick={this.shareClickFB.bind(this)} target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(window.location.href) + "&amp;src=sdkpreparse"} className="fb-xfbml-parse-ignore">Share</a>
              </div>
            </div>
            <div className="col"></div>
          </div>
          </React.Fragment>
        ): (
            <small className="text-center text-muted">
                <span className="" role="img" aria-label="Powered through">⚡</span> Powered through <a href="https://bytetrack.bytebeacon.com" className="text-primary">ByteTrack service</a> 
            </small>
        )}


      </PageBlankLayout>
    )
  }
}

export default withRouter(Page);
