import React from 'react';
import {Link} from 'react-router-dom';
import { Drawer, ButtonToolbar, InputNumber } from 'rsuite';
import alertify from 'alertifyjs';

import Moment from 'react-moment';

// import {shadeColor} from 'utils/color';
import {Rainbow} from 'utils/color';

import { DomainBadge } from 'component/reuse/displays';
import API, {handleError} from 'utils/xhr';


export default class Card extends React.Component{
  state = {
    item: null,
  }

  onLog(val){
    const state = this.state;
    if (state.item === null){
      state.item = this.props.item;
    }
    state.item.activity.count.current += parseInt(val);
    this.setState(state);
  }

  render(){

    const hideComplete = this.props.hideComplete;
    const viewOnly = this.props.viewOnly;

    const item = this.state.item || this.props.item;
    
    const complete_percentage = parseInt((item.activity.count.current/item.activity.count.target) * 100);
    const is_completed = complete_percentage >= 100;

    if (hideComplete && is_completed){
      return '';
    }
    
    var complete_bg = {
      // borderColor: item.template.color,
    };
    if (is_completed){
      var color_bg = new Rainbow();
      color_bg.setNumberRange(0, 100);
      color_bg.setSpectrum(item.template.color, '#ffffff');
      // complete_bg['backgroundColor'] = color_bg.colourAt(93);
    }

    var log_color_bg = new Rainbow();
    log_color_bg.setNumberRange(0, 100);
    log_color_bg.setSpectrum('#fff9f9', '#fbfbfb');
    // var log_color_fg = new Rainbow();
    // log_color_fg.setNumberRange(0, 100);
    // log_color_fg.setSpectrum('#ffd6d6', '#ffffd6', '#d6ffd6');
    // const log_color_fg = (complete_percentage < 40)? '#f3aea8': ((complete_percentage < 100)? '#f8d9af': '#9be0d2' );
    const log_color_fg_class = (complete_percentage < 40)? 'danger': ((complete_percentage < 100)? 'warning': 'success' );
    
    // const view_class = 'col-md-4';
    const view_class = '';
    const card_style = { 
      minWidth: (viewOnly)? '100px':'160px',
    };

    if (viewOnly){

      var activity_time = item.activity.meta.start;
      return (
        <div className={"m-1 list-group "} style={{backgroundColor: log_color_bg.colourAt(complete_percentage)}}>
          <div className={"rounded align-items-start m-0 p-0 " + ((is_completed)? '  highlight-success-1 border ': ' highlight-light-5 border border-danger ')}  >

            <div className=" p-0  mx-2">
              <div className="text-right text-size-1 black-text-6">
                <Moment fromNow>{activity_time}</Moment>
              </div>

              <div 
                className={"p-1 text-black-4 small d-flex w-100 justify-content-between align-items-center " + (is_completed? '  ' : '  ')}
                style={{}}
              >

                <div className={"black-text-4 text-size-3 family-code "} >
                  {/* <div className="text-size-1 m-0 p-0">
                    <Moment format="YYYY">{activity_time}</Moment>
                  </div> */}
                  <div className="text-size-5 m-0 p-0">
                    <Moment format="DD">{activity_time}</Moment>
                  </div>
                  <div className="text-size-2 uppercase m-0 p-0">
                    <Moment format="MMM">{activity_time}</Moment>
                  </div>
                </div>

                {/* <div className="progress" style={{minWidth: '50%'}}>
                  <div className={"progress-bar highlight-" + log_color_fg_class + "-9"} style={{width: complete_percentage+"%"}}></div>
                </div> */}

                <div className={"text-"+log_color_fg_class}>{complete_percentage}%</div>

                <LogActivity onLog={this.onLog.bind(this)} template_xid={item.template.xid} activity_xid={item.xid} color={item.template.domain.color} class_type={is_completed? log_color_fg_class: null}/>

              </div>
            </div>

          {displayNotes(item.logs, false)}

          </div>
        </div>
      )
    }

    return (
      <div className={view_class + " m-1 p-0 "  + ((is_completed)? " black-text-4": " black-text-1")} style={card_style}>
      <div className={"list-group "}>
        <div className={"list-group-item align-items-start m-0 p-0" + ((is_completed)? ' dark-highlight-1 ': '')} style={complete_bg} >

          <div className=" p-0 ">

            <div className="text-center mx-1 ">

            {/* d-flex w-100 justify-content-between  */}
              <div className="px-1 pt-1">
                <div className="text-right">
                  <DomainBadge domain={item.template.domain} />
                </div>

                <Link to={"/view/" + item.template.xid} className="text-left text-black">
                  <h5 className={"text-size-3 mt-1 mb-2"}>{item.template.title}</h5>
                </Link>

              </div>

              <hr className=" my-1" />

              <div className="">
                <small className="text-center d-flex justify-content-center">
                  <span className="mx-2">
                    <div className="text-size-3 mx-1 black-text-4 strong ">{item.activity.count.current}</div>
                    <div className="text-size-1 black-text-4 strong ">{" logged "}</div>
                  </span>
                  <span className=" align-self-center text-size-1 black-text-7">{" of "}</span>
                  <span className="mx-2 ">
                    <div className="text-size-3 mx-1 black-text-7">{item.activity.count.target}</div>
                    <div className="text-size-1 black-text-7">{" scheduled"}</div>
                  </span>
                </small>
              </div>

              <div className="d-flex w-100 justify-content-between mx-1">

                <div className="d-flex w-100 align-items-center">
                <div className="progress m-1" style={{height: '5px', width: '90%', backgroundColor: '#ddd'}}>
                  <div className={"dark-bg-4 progress-bar " + ((is_completed)? '  ': ' progress-bar-striped progress-bar-animated ')} role="progressbar" 
                    style={{
                      width: complete_percentage+'%', 
                    }} 
                    ></div>
                </div>
                </div>
                
                <LogActivity onLog={this.onLog.bind(this)} template_xid={item.template.xid} activity_xid={item.xid} color={item.template.domain.color} />
                
              </div>
            </div>

            {displayNotes(item.logs, true)}

          </div>
        </div>
      </div>
    </div>
  )
  }
}



class LogActivity extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      activity: null,
      log_quantity_count: 1,
      note: {
        link: '',
        text: '',
      }
    };
    this.close = this.close.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount(){
    // Careful here .. it runs for all activity cards even if you don't click them
  }

  close() {
    this.setState({
      show: false,
      activity: null,
  });
  }
  
  toggleDrawer() {

    this.setState({ show: true });

    const url = '/habits/' +this.props.template_xid+ '/activities/' + this.props.activity_xid;
    API.get(url).then(response =>{
      const state = this.state;
      state.activity = response.data.data.activity;
      this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onCountChange(val, e){
    const state = this.state;
    state.log_quantity_count = val;
    this.setState(state);
  }

  onLogActivity(){
    const data = {
      quantity: parseInt(this.state.log_quantity_count),
      href: this.state.note.link,
      text: this.state.note.text,
    }
    
    const url = '/habits/' +this.props.template_xid+ '/activities/' + this.props.activity_xid;
    API.post(url, data).then(response =>{
      const state = this.state;
      if (state.note.text){
        state.activity.logs.unshift({
          text: state.note.text,
          href: state.note.link,
        })
      }
      this.props.onLog(state.log_quantity_count);
      state.log_quantity_count = 1;
      state.note.text = '';
      state.note.link = '';
      state.show = false;
      state.activity = null;
      this.setState(state);
      const msg = "Activity logged successfully!";
      alertify.success(msg);

    },(error) =>{
      const msg = "Unable to log this activity!";
      alertify.error(msg);
      handleError(error, this.props.history, window.location.pathname);
    })

  }

  onNoteTextChange(e){
    const state = this.state;
    state.note.text = e.currentTarget.value;
    this.setState(state)
  }

  onNoteLinkChange(e){
    const state = this.state;
    state.note.link = e.currentTarget.value;
    this.setState(state)
  }

  render() {
    const activity = this.state.activity;

    // const class_type = {
    //   'default': ' bg-light text-black-3 border ',
    //   'success': ' bg-white text-success border-success ',
    //   'warning': ' bg-white text-warning border-warning ',
    //   'danger': ' bg-white text-danger border-danger ',
    //   '': '',
    // }[this.props.class_type || '']

    if (activity === null){      
      return (
        <ButtonToolbar>
          <div onClick={this.toggleDrawer}  className={"text-size-4 black-text-3 btn btn-rounded  " }>
            <i className="fa fa-plus"></i>
          </div>
        </ButtonToolbar>
      )
    }
    
    // const complete_percentage = parseInt((activity.activity.count.current/activity.activity.count.target) * 100);
    // const is_completed = complete_percentage >= 100;

    // var complete_check = {};
    // if (is_completed){
    //   complete_check = {backgroundColor: activity.template.color,}
    // }

    const is_mobile = window.innerWidth < 640;

    return (
      <div>
        <ButtonToolbar>
          <div onClick={this.toggleDrawer}  className={"text-size-4 black-text-6 btn btn-rounded  " }>
            <i className="fa fa-plus"></i>
          </div>
        </ButtonToolbar>
        <Drawer
          backdrop={true}
          full={is_mobile}
          open={this.state.show}
          onClose={this.close}
        >
          <Drawer.Header>
            <Drawer.Title>Log activity</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>

            {/* {(is_completed)? (<span className="badge text-white" style={complete_check}>completed</span>):('')} */}

            <div className="d-flex w-100 justify-content-between">

              <span className="text-size-10 mr-2">
                <DomainBadge domain={activity.template.domain} />
                {activity.template.title}
              </span>
              < small className="text-muted text-center">
                <div className="">range:</div>
                <div className="">{activity.activity.meta.start}</div>
                <div className="">{activity.activity.meta.end}</div>
              </ small>
            </div>

            <hr className="" />

            <div className="my-2">
              <div className="form-group">
                <label className="small text-muted">Log activity:</label>
                <input type="text" className="form-control my-2" placeholder="add optional note in here" value={this.state.note.text} onChange={this.onNoteTextChange.bind(this)}  />
                <input type="text" className="form-control my-2" placeholder="anything to link?" value={this.state.note.link} onChange={this.onNoteLinkChange.bind(this)}  />
              </div>

              <div className="my-2">
                <InputNumber 
                  min={0} 
                  onChange={this.onCountChange.bind(this)} 
                  value={this.state.log_quantity_count} 
                  placeholder="1"
                />
              </div>

              <button onClick={this.onLogActivity.bind(this)}  className="btn btn-rounded btn-success">
                Log {this.state.log_quantity_count} {(this.state.log_quantity_count === 1)? (activity.template.count.unit.singular): (activity.template.count.unit.plural)}
              </button>
            </div>

            {displayNotes(activity.logs, false)}

          </Drawer.Body>
          <Drawer.Actions>
            <div className="my-1">
              <button onClick={this.close} type="button" className="btn btn-danger btn-rounded mx-2">Cancel</button>
            </div>
          </Drawer.Actions>
        </Drawer>
      </div>
    );
  }

}

function displayNotes(logs, truncate){
  const filtered_logs = logs.filter(function(log){ return (log.href && log.href !== '') || (log.text && log.text !== '')});

  return (
    <div className="">
    {(filtered_logs.length === 0)? '': (
      <div className="row dark-bt-1-1 bg-white rounded">
        <div className="my-2 p-2 ">
          <div className="text-black-6">
            {/* <div className="text-size-3"><i className="">Notes:</i></div> */}
            <div className="text-size-2 text-black-3">
              {filtered_logs.map(function(log, idx){
                return (<div key={idx}>{displayLog(log, truncate)}</div>)
              })}
            </div>
          </div>
        </div>
      </div>
    )}
    </div>
  )

}

function displayLog(log, truncate){
  const classStyle = 'py-1 m-0';
  
  if (log.href && log.href !== ''){
    return (
      <span className={" " + classStyle}>
        <i className="fas fa-caret-right mr-2"></i>
        <a href={log.href} className="">{(log.text === null || log.text === '')? "link": ((truncate)? truncateString(log.text, 30):log.text)}</a>
      </span>
    )
  }

  if (log.text && log.text !== ''){
    return (
      <span className={" " + classStyle}>
        <i className="fas fa-caret-right mr-2"></i>
        {(truncate)? truncateString(log.text, 30):log.text}
      </span>
    )
  }

  return ''
}

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}