import React from 'react';
import ReactMarkdown from 'react-markdown';
// import {Rainbow} from 'utils/color';

import { DomainBadge } from 'component/reuse/displays';
import API, {handleError} from 'utils/xhr';



export class ActivityViewer extends React.Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      template_xid: props.template_xid || null,
      template: props.template || null,
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;

    if (this.state.template !== null){
      const state = this.state;
      state.template_xid = this.state.template.xid;
      this.setState(state)
      return;
    }
    
    if (this.state.template_xid === null){
      return;
    }

    const url = '/habits/' + this.state.template_xid;    
    
    API.get(url).then(response => {
      if (this._isMounted) {
        const state = this.state;
        state.template = response.data.data.template;
        this.setState(state);
      }
      
    }, (error) => {
      handleError(error);handleError(error, this.props.history, window.location.pathname);
    });
  }

  render(){
    const template = this.state.template;

    if (template === null){
      return '';
    }

    return (
      <div className="row">
        <div className=" m-0 p-0 col-sm-12 col-md-4 ">
          <ActivityTemplateCard template={template} />
        </div>

        <div className=" m-0 p-0 col-sm-12 col-md-8 ">
          <div className="border m-2 p-2 black-text-3">
            <ReactMarkdown children={template.notes || 'No notes added!'} />
          </div>
        </div>

      </div>
    )
  }
}


class ActivityTemplateCard extends React.Component {

  render (){
    const template = this.props.template;

    if (template === undefined || template === null){
      return '';
    }

    // var color_bg = new Rainbow();
    // color_bg.setNumberRange(0, 100);
    // color_bg.setSpectrum(template.domain.color, '#FFFFFF');

    return (
      <div className=" m-2 p-3 border dark-highlight-1" style={{}}>

        <div className="d-flex w-100 justify-content-between px-1 pt-1">
          <h5 className={"text-size-8 text-center  mt-1 mb-2"}>{template.title}</h5>

          <div className="text-right">
            <DomainBadge domain={template.domain} />
          </div>
          
        </div>

        <hr className="my-2" />

        <div className="text-center text-black">
          <div className="text-size-2 mx-2">
            <i className="fa fa-sync"></i>
          </div>
          <div className="text-size-8">
            {template.count.target}
          </div>
          <div className="text-size-2 mx-2">
            {" " + ((template.count.target === 1)? template.count.unit.singular: template.count.unit.plural) + " " + (template.frequency.name)}
          </div>
        </div>
        
      </div>
    )
  }
}
