import React from "react";


export function SectionTitle(props){
  const is_mobile = window.innerWidth < 640;
  return (
  <h1 className={"my-4 pt-1 pb-1 " + ((is_mobile)? ' text-center ': '')}>
    {(props.icon_class)? (<i className={"mr-3 " + props.icon_class}></i>) : ''}
    {props.title}
  </h1>
  );
}


export function ParaTitle(props){
  return (<h5 className="text-size-4 text-muted my-2">{props.children}</h5>);
}


export function FooterSectionTitle(props){
  return(<h5 className="text-uppercase mt-5 mb-3 text-black-1">{props.title}</h5>)
}
