import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import { Avatar } from 'rsuite';
import { Icon } from '@rsuite/icons';
import ReactTooltip from 'react-tooltip';

import API, {handleError} from 'utils/xhr';
import {ContainerSection} from 'component/reuse/containers';

import SideNavLayout from 'component/page/side_nav';
import {LoaderRipple} from 'component/loader';
import AddNewCard from 'component/reuse/card/add';
import TemplateCard from 'component/reuse/card/template';
import {SectionTitle, ParaTitle} from 'component/reuse/title';


class Page extends React.Component {
  state = {
    schedule: null,
  }

  componentDidMount(){
    const url = '/schedule';
    API.get(url).then(response => {
      this.setState({schedule: response.data.data.schedule,});
    }, (error) => {
      handleError(error, this.props.history, window.location.pathname);
    });
  }

  render (){
    return (
      <SideNavLayout id="schedule-page" eventKey="schedule" >
        <ReactTooltip effect="solid"/>
        <SectionTitle title="Scheduled targets"  icon_class="fa fa-calendar-alt" />
        {/* <small className="text-muted">Your target schedule template</small> */}

        <div>
        {(this.state.schedule === null)? (<LoaderRipple />): (<DisplaySchedule schedule={this.state.schedule} />)}
        </div>
      </SideNavLayout>
    )
  }
}


function DisplaySchedule(props){
  const schedule = props.schedule || [];

  if (schedule.length === 0){
    return (
      <div className="my-3">
        You don't have any schedule set at this moment! Start by <Link to="/create">creating one</Link>.
      </div>
    )
  }

  return (
    <React.Fragment>
      {schedule.map(function(item, idx){
        return (<ScheduleSection key={"_"+idx} detail={item}/>)
      })}
    </React.Fragment>
  )
}


function ScheduleSection(props){
  const detail = props.detail;

  var cnt_active = 0;
  var cnt_paused = 0;
  detail.items.forEach(function(item, idx){
     if (item.template.status  === 'active'){cnt_active += 1;}
     if (item.template.status  === 'paused'){cnt_paused += 1;}
     return;
  })
  // const cnt_total = detail.items.length;

  return (
    <div className="my-3 dark-highlight-1">
      <ContainerSection className="">

      <div className="d-flex w-100 justify-content-between">

        <div className="">
          <ParaTitle>
            <span className="">{detail.title}: </span>
          <sup>
            <span className="mx-1">
              {/* <span className="text-success" data-tip="Scheduled activities" data-type="dark">{cnt_active}<i className="fa fa-play ml-1"></i></span>  */}
              <span className="" data-tip="Scheduled activities" data-type="dark">{cnt_active} Active</span> 
              <span className="mx-1">/ </span>
              {/* <span className="text-danger" data-tip="Paused activities" data-type="error">{cnt_paused}<i className="fa fa-pause ml-1"></i></span>  */}
              <span className="text-danger" data-tip="Paused activities" data-type="error">{cnt_paused} Paused</span> 
            </span>
          </sup>
          </ParaTitle>
        </div>

        <AddNewCard q_str={"?frequency="+detail.frequency.name}/>

      </div>

        <div className="row flex-row flex-nowrap" style={{"overflowX": "auto"}}>
          
          {(detail.items.length === 0)? (
            <div className="p-2 m-2"><Avatar circle size="lg"><Icon icon="question" /></Avatar><p className="m-2">No targets! Start by creating one. </p></div>
          ) : ('')}

          {detail.items.map(function(item, idx){
            return (<TemplateCard key={"_"+idx} item={item} />)
          })}

        </div>
        
      </ContainerSection>
    </div>
  )
}

export default withRouter(Page);
