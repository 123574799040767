import React from 'react';
// import {Helmet} from "react-helmet";

import Nav from 'component/navigation/topbar';
import {LandingFooter} from 'component/footer';


export default function LandingLayout(props) {
  return (
    <div id={props.id} className="page-wrapper">
      {/* <Helmet>
        <meta property="og:title" content="Track recurring events and get the encouragement to stick to the schedule that leads you to a better you" />
        <meta property="og:type" content="product" />
        <meta property="og:url" content="https://bytetrack-app.bytebeacon.com" />
        <meta property="og:image" content="https://bytetrack-app.bytebeacon.com/logo.png" />
        <meta property="og:site_name" content="ByteTrack | Track recurring activities" />
        <meta property="og:description" content="Track recurring events and get the encouragement to stick to the schedule that leads you to a better you!" />
      </Helmet> */}

      <Nav />

      <div className="landing-content my-5 text-black-2">
        {props.children}
      </div>

      <LandingFooter />
    </div>
  );
}
