import React from 'react';
import {  Notification } from 'rsuite';
import Select from 'react-select'
import ReactMarkdown from 'react-markdown';
import alertify from 'alertifyjs';

import { DomainBadgeEditable} from 'component/reuse/displays';
import API, {handleError} from 'utils/xhr';
import {isDemoAccount, isPilotAccount} from 'utils/authentication';


////////////////////////////////////////////////////////////////////////
// CLEANUP
import {
  generate_new_activity_template,
  build_title_for_template,
  // build_count_for_template,
  build_frequency_for_template,
} from 'constants/mock/generators';
////////////////////////////////////////////////////////////////////////


export class ActivityBuilder extends React.Component {
  _isMounted = false;

  constructor(props){
    super(props);
    const frequency = props.frequency || 'weekly';
    this.state = {
      edited: false,
      editing_template: generate_new_activity_template(", twice, " + frequency),
      modified_frequency: false,
      template_xid: props.template_xid || null,

      template_notes: `#### Add optional notes!
Add personal notes in here. Drop links to articles, videos, quotes that motivate you.

We support markdown syntax as well, so **feel free** to *format your text* 

You can even add:
  - lists
  - here

---
Checkout the *[Markdown Guide](https://www.markdownguide.org/basic-syntax/)* for more documentation.`,
      template_notes_edited: false,
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    if (this.state.template_xid === null){
      return;
    }

    const url = '/habits/' + this.state.template_xid;

    API.get(url).then(response => {
      if (this._isMounted) {
        const state = this.state;
        state.editing_template = response.data.data.template;
        state.template_notes = response.data.data.notes || '';
        state.editing_template.domain_xid = state.editing_template.domain.xid;
        state.edited = false;
        this.setState(state);
      }
      
    }, (error) => {
      handleError(error);handleError(error, this.props.history, window.location.pathname);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ref_template && (prevProps.ref_template.domain_xid !== this.props.ref_template.domain_xid)){      
      // const template = (this.state.edited)? (this.state.editing_template): (this.props.ref_template || this.state.editing_template) ;
      const state = this.state;
      state.editing_template = this.props.ref_template;
      this.setState(state);
    }
  }

  checkValidity(template){
    var msg ='';
    if (isDemoAccount()){
      msg = "This is a demo account and cannot perform this action.";
      alertify.warning(msg);
      return false;
    }

    if (isPilotAccount() === 'false'){
      msg = "This account has not been enabled for pilot access yet, hence you can't perform this action! Please contact info@bytebeacon.com for early access OR the person who granted you pilot access.";
      alertify.warning(msg);
      return false;
    }

    if (template.title === ''){
      msg = "Missing activity title! \n\nWe need one eg: Workout, Drink water, Write a blog";
      alertify.error(msg);
      return false;
    }

    if (template.domain_xid === undefined || template.domain_xid === null){
      msg = "Please select (or create) a domain for this Target!";
      alertify.error(msg);
      return false;
    }

    return true;
  }

  onEdit(e){
    const template = this.state.editing_template;
    if (!this.checkValidity(template)){
      return;
    }
    
    const url = '/habits/' + this.state.template_xid;
    const data = {
      template: template,
      modified_frequency: this.state.modified_frequency,
      notes: this.state.template_notes,
    }
    API.post(url, data).then(response =>{

      var msg = '';
      if (response.data.data.updated){
        msg = 'Activity edited!';
        alertify.success(msg);
  
      } else {
        msg = 'Unable to edit Activity!';
        alertify.error(msg);
      }

    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onCreate(e){
    const template = (this.state.edited)? (this.state.editing_template): (this.props.ref_template || this.state.editing_template) ;
    
    if (!this.checkValidity(template)){
      return;
    }

    const url = '/habits';
    const data = {
      template: template,
      notes: (this.state.template_notes_edited)? this.state.template_notes: null,
    }
    API.post(url, data).then(response =>{
      const state = this.state;
      const domain_xid = state.editing_template.domain_xid;
      // state.editing_template = generate_new_activity_template(", twice, weekly");
      state.editing_template = (this.props.ref_template || generate_new_activity_template(", twice, weekly"));
      state.editing_template.domain_xid = domain_xid;
      this.setState(state);

      Notification['success']({
        title: 'Activity created!',
        description: (
          <div className="">
            Activity successfully created! Check your <a href='/schedule' className=''>schedule now</a>
          </div>
        )
      });

    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onChange(change_type, change_value){
    const state = this.state;
    
    if (state.edited === false && this.props.ref_template){
      state.editing_template = this.props.ref_template;
    }
    state.edited = true;

    if (change_type === 'domain'){
      state.editing_template.domain_xid = change_value;
    }

    if (change_type === 'title'){
      state.editing_template.title = build_title_for_template(change_value);
    }

    if (change_type === 'count'){
      // state.editing_template.count = build_count_for_template(change_value);
      state.editing_template.count.target = change_value;
    }

    if (change_type === 'unit'){
      state.editing_template.count.unit = {'singular': change_value, 'plural': change_value};
    }

    if (change_type === 'frequency'){
      if (state.template_xid){
        state.modified_frequency = true;
      }
      state.editing_template.frequency = build_frequency_for_template(change_value);
    }

    this.setState(state);
  }

  onDomainChange(domain_xid){
    this.onChange('domain', domain_xid);
  }

  onActivityChange(e){
    const change_value = e.currentTarget.value;
    this.onChange('title', change_value);
  }
  onCountChange(e){
    const regex = /^[0-9\b]+$/;
    const value = e.target.value;

    if (value === '' || regex.test(value)) {
      var change_value = parseInt(value);
      // var change_value = parseInt(value) || 1;
      // change_value = (change_value < 1)? 1: change_value;
      this.onChange('count', change_value);
    }
  }
  onUnitChange(e){
    const change_value = e.currentTarget.value;
    // this.onChange('unit', {'singular': change_value, 'plural': change_value});
    this.onChange('unit', change_value);
  }
  // onFrequencyChange(val, e){
  onFrequencyChange(val, action){
    const change_value = val.value;
    this.onChange('frequency', change_value);
  }

  onNotesChange(e){
    const change_value = e.currentTarget.value;
    const state = this.state;
    state.template_notes = change_value;
    state.template_notes_edited = true;
    this.setState(state)
  }

  render(){
    const ref_template = (this.state.edited)? (this.state.editing_template): (this.props.ref_template || this.state.editing_template) ;

    const notes = (this.state.template_notes_edited)? this.state.template_notes: ((ref_template.notes)? ref_template.notes: this.state.template_notes)
    // const notes = this.state.template_notes || (ref_template.notes); 
    
    return (
      <div className="row">
        <div className=" m-0 p-0 col-sm-12 col-md-3 ">

          <ActivityTemplateCard 
            template={ref_template} 
            onDomainChange={this.onDomainChange.bind(this)}
            onActivityChange={this.onActivityChange.bind(this)} 
            onCountChange={this.onCountChange.bind(this)} 
            onUnitChange={this.onUnitChange.bind(this)} 
            onFrequencyChange={this.onFrequencyChange.bind(this)} 
            template_xid={this.state.template_xid}
            onCreate={this.onCreate.bind(this)}
            onEdit={this.onEdit.bind(this)}
          />

        </div>

        <div className=" m-0 p-0 col-sm-12 col-md-4">
          <div className=" m-2 ">
          <h5 className="">Edit Notes:</h5>
          <textarea 
            className="form-control my-2" 
            onChange={this.onNotesChange.bind(this)} 
            value={notes}
            name="" id="" cols="30" rows="15" maxLength="4096" 
            placeholder="Add personal notes in here. Drop links to articles, videos, quotes that motivate you.">
          </textarea>
          <a href="https://www.markdownguide.org/basic-syntax/" className="small" rel="noopener noreferrer" target="_blank" >Markdown Basics</a>
          </div>
        </div>

        <div className=" m-0 p-0 col-sm-12 col-md-5 ">
          <h5 className="m-2">Preview:</h5>
          <div className="border m-2 p-2 highlight-light-5">
            <ReactMarkdown children={notes} />
          </div>
        </div>

      </div>
    )
  }
}


class ActivityTemplateCard extends React.Component {

  render (){
    const tmpl = this.props.template;

    const template_xid = this.props.template_xid;
    const onCreate = this.props.onCreate;
    const onEdit = this.props.onEdit;

    const onDomainChange = this.props.onDomainChange;
    const onActivityChange = this.props.onActivityChange;
    const onCountChange = this.props.onCountChange;
    const onUnitChange = this.props.onUnitChange;
    const onFrequencyChange = this.props.onFrequencyChange;

    if (tmpl === undefined || tmpl === null){
      return '';
    }

    const frequencies = [
      "daily",
      "weekly",
      "monthly",
      "yearly",
    ]
    var frequency_options = [];
    for(var i=0; i<frequencies.length; i++){
      frequency_options.push({
        "value": frequencies[i],
        "label": frequencies[i].charAt(0).toUpperCase() + frequencies[i].slice(1),
      })
    }

    const domain_xid = (tmpl.domain)? tmpl.domain.xid: null;

    const tmpl_name = tmpl.frequency.name;
    const selectedFrequency = {
      value: tmpl_name || "weekly", 
      label: (tmpl_name)? (tmpl_name.charAt(0).toUpperCase() + tmpl_name.slice(1)) : "Weekly",
    }

    return (
      <div>
        <div className=" m-2 p-3 dark-highlight-1  border ">

          <div className="my-1">
            <DomainBadgeEditable onDomainChange={onDomainChange} domain_xid={domain_xid} placeholder="Pick domain"/>
          </div>

          <hr className=""/>

          <input 
            type="text" 
            className={"text-black form-control my-1 text-size-5"} 
            onChange={onActivityChange} 
            value={tmpl.title} 
            placeholder="Activity Name"
          />

          <div className="my-1">
            <div className="row">
              <div className="col mr-1 p-0">
                <input 
                  className={"text-black form-control"} 
                  type="number" 
                  onChange={onCountChange} 
                  value={tmpl.count.target} 
                  placeholder="2" 
                />

                {/* <InputNumber 
                  min={1} 
                  onChange={onCountChange} 
                  value={tmpl.count.target} 
                  placeholder="2"
                /> */}
              </div>
              <div className="col ml-1 p-0">
                <input 
                  type="text" 
                  className={"text-black form-control"} 
                  onChange={onUnitChange} 
                  value={(tmpl.count.target === 1)? (tmpl.count.unit.singular): (tmpl.count.unit.plural)} 
                  placeholder="times"
                />
              </div>
            </div>        
          </div>

          <div className="my-1">
            <Select 
              options={frequency_options} 
              onChange={onFrequencyChange} 
              defaultValue={selectedFrequency}
              value={selectedFrequency}
            />
          </div>

          <div className="my-2 text-center">

            {(template_xid === undefined || template_xid === null)? (
              <button 
                type="button" 
                className="m-2 btn-lg btn btn-rounded text-white "  
                style={{backgroundColor: tmpl.color || "#0e91b5" }} 
                onClick={onCreate}
                >
                  <i className="fa fa-plus mr-2"></i>Start
              </button>
            ): (
              <button 
                type="button" 
                className="m-2 btn-lg btn btn-rounded text-white "  
                style={{backgroundColor: tmpl.color }} 
                onClick={onEdit}
                >
                  <i className="fas fa-pencil-alt mr-2"></i>Update
              </button>
            )}
          </div>

          
        </div>
      </div>
    )
  }
}
