import React  from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";


function App(props) {

  return (
    <React.Fragment>

      <div className=" py-5" style={{marginLeft: '4px', marginRight: '4px'}}>
        {props.children}
      </div>

    <MobileNavbar.Wrapper className="dark-bg-3">
      <MobileNavbar.Items className="m-0 p-0 my-1">
        
        <MobileNavbar.Item className={" rounded " + ((window.location.pathname === '/active')? ' bg-primary ': ' ')}>
          <Link to="/active">
          <MobileNavbar.Icon>
          <i className="white-text-1 fas fa-calendar-check"></i>
          </MobileNavbar.Icon>
          <small className={"text-center text-white"}>
            <small>Track</small>
          </small>
          </Link>
        </MobileNavbar.Item>
        
        <MobileNavbar.Item className={" rounded " + ((window.location.pathname === '/schedule')? ' bg-primary ': ' ')}>
          <Link to="/schedule">
          <MobileNavbar.Icon>
          <i className="white-text-1 fas fa-calendar"></i>
          </MobileNavbar.Icon>
          <small className={"text-center text-white"}>
            <small>Schedule</small>
          </small>
          </Link>
        </MobileNavbar.Item>

        <MobileNavbar.Item className={" rounded " + ((window.location.pathname === '/create')? ' bg-primary ': ' ')}>
          <Link to="/create">
          <MobileNavbar.Icon>
          <i className={"fas fa-plus p-1 btn-rounded " + ((window.location.pathname === '/create')? '  bg-white text-primary ': ' white-text-1 bg-primary ')}></i>
          </MobileNavbar.Icon>
          <small className={"text-center text-white"}>
            <small>Create</small>
          </small>
          </Link>
        </MobileNavbar.Item>

        <MobileNavbar.Item className={" rounded " + ((window.location.pathname === '/analytics')? ' bg-primary ': ' ')}>
          <Link to="/analytics">
          <MobileNavbar.Icon>
          <i className="white-text-1 fas fa-chart-bar"></i>
          </MobileNavbar.Icon>
          <small className={"text-center text-white"}>
            <small>Analytics</small>
          </small>
          </Link>
        </MobileNavbar.Item>

        <MobileNavbar.Item className={" rounded " + ((window.location.pathname === '/settings')? ' bg-primary ': ' ')}>
          <Link to="/settings">
          <MobileNavbar.Icon>
          <i className="white-text-1 fas fa-cog"></i>
          </MobileNavbar.Icon>
          <small className={"text-center text-white"}>
            <small>Settings</small>
          </small>
          </Link>
        </MobileNavbar.Item>

      </MobileNavbar.Items>

    </MobileNavbar.Wrapper>

    </React.Fragment>

  );
}


const MobileNavbar = {
  Wrapper: styled.nav`
    

    align-self: flex-start;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
    flex: 1;
    padding: 0.25rem;

    justify-content: space-around;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.2rem;
  `,
  Icon: styled.span``
};


export default App;