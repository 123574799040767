import React from "react";
import CreatableSelect from 'react-select/creatable';

import API, {handleError} from 'utils/xhr';


export class DomainBadge extends React.Component{
  render (){
    const domain = this.props.domain;
    return (
      <h5 className=" uppercase strong text-size-1" 
        style={{color: domain.color}}
        // style={{color: 'white', backgroundColor: domain.color}}
      >
        {domain.domain}
      </h5>
      )
  }
}


export class DomainBadgeEditable extends React.Component{
  _isMounted = false;

  constructor(props){
    super(props);    
    this.state = {
      domain_list_raw: [],
      selected_value: null,
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount(){
    this._isMounted = true;
    const url = '/domain';
    API.get(url).then(response =>{
      if (this._isMounted) {
        const state = this.state;
        state.domain_list_raw = response.data.data.domain_list;
        this.setState(state);
      }
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onSelect(item, action){

    if (action.action === 'create-option') {
      const url = '/domain';
      const data = {domain: item.label}
      API.post(url, data).then(response =>{
        const domain_xid = response.data.data.domain_xid;
        
        const state = this.state;
        state.selected_value = domain_xid;
        state.domain_list_raw.push({
          domain: item.label,
          xid: domain_xid,
        });
        this.setState(state);
        
        this.props.onDomainChange(domain_xid);
      },(error) =>{
        handleError(error, this.props.history, window.location.pathname);
      })
    } else {
      const domain_xid = item.value;
      this.props.onDomainChange(domain_xid);
      
      const state = this.state;
      state.selected_value = domain_xid;
      this.setState(state)
    }
  }

  render (){
    const selected_value = this.state.selected_value || this.props.domain_xid || null;
    const placeholder = this.props.placeholder || 'Select domain';
      
    var domain_list = [];
    var selectedItem = null;
    this.state.domain_list_raw.map((item, idx) => {
      if (item.xid === selected_value){
        selectedItem = {
          "label": item.domain,
          "value": item.xid,
        }
      }
      domain_list.push({
        "label": item.domain,
        "value": item.xid,
      })
      return null;
    })

    return (
      <React.Fragment>
        <CreatableSelect 
          options={domain_list} 
          onChange={this.onSelect.bind(this)}
          value={selectedItem}
          placeholder={placeholder}
        />
      </React.Fragment>
    )
  }
}
