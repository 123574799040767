import React from 'react';
import { withRouter} from 'react-router-dom';
import alertify from 'alertifyjs';
import Select from 'react-select'

import SideNavLayout from 'component/page/side_nav';
import {LoaderRipple} from 'component/loader';
import API, {handleError} from 'utils/xhr';
import {TIMEZONES} from 'component/constant';
import { setTimezone } from 'utils/authentication';
import {SectionTitle} from 'component/reuse/title';


class Page extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      profile_details: null,
      selected_value: null,
    }
  }

  componentDidMount(){
    const url = '/users/me';

    API.get(url).then(response => {

      this.setState(
        {profile_details: response.data.data,
      });

    }, (error) => {
      handleError(error, this.props.history, window.location.pathname);
    });
  }

  onTZSelect(val, item, e){
    const url = '/users/me/timezone';
    const data = {
      timezone: val.value
    }
    API.post(url, data).then(response =>{
      
      const state = this.state;
      state.selected_value = val.value;
      this.setState(state);
      setTimezone();
      alertify.success('Timezone updated!', 1.5);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  render (){

    const profile_details = this.state.profile_details || null;

    const is_mobile = window.innerWidth < 640;
    const profileStyle = is_mobile? {border: '1px solid black', borderRadius: '50%', height: '75px', width: '75px'}: {border: '1px solid black', borderRadius: '50%', height: '200px', width: '200px'}

    const selected_value_key = this.state.selected_value || ((profile_details === null)? ('US/Eastern'): (profile_details.info.timezone));
    // const selected_value_key = 'US/Eastern';
    const selected_value = {
      "label": selected_value_key,
      "value": selected_value_key,
    }
    if (profile_details !== null && profile_details.info.timezone){
      setTimezone();
    }
      
    var timezone_list = [];
    TIMEZONES.map((item, idx) => {
      timezone_list.push({
        "label": item,
        "value": item,
      })
      return null;
    })

    return (
      <SideNavLayout id="settings-page" eventKey="settings">
        <SectionTitle title="Settings" icon_class="fa fa-cog" />

        <div className={(is_mobile)? "p-1": "p-5"}>

        {(profile_details === null)? (
          <LoaderRipple />
        ): (
          <div className="">
            <div className="row m-0 p-0">
              <div className="col-3 m-0 p-0">
                <img className="" style={profileStyle} src={profile_details.info.img_src} alt=""/>
              </div>
              <div className="col-9">
                <div className="">
                  <h1 className=" text-size-5">{profile_details.info.name}</h1>
                  <hr className=""/>
                  <p className="text-muted text-size-3">Joined: {profile_details.info.joined_date}</p>
                  <Select 
                    options={timezone_list} 
                    onChange={this.onTZSelect.bind(this)}
                    defaultValue={selected_value}
                    value={selected_value}
                  />
                </div>
              </div>
            </div>

            <div className={(is_mobile)? " ": " mx-5 px-5 "}>

            <hr className="py-3"/>

            <div className="row m-5">
              <div className="col">
                <span className="text-black strong text-size-3">{profile_details.stats.domain}</span><p className="text-muted">domain</p>
              </div>
              <div className="col">
                <span className="text-black strong text-size-3">{profile_details.stats.activities}</span><p className="text-muted">activities</p>
              </div>
              <div className="col">
                
              </div>
              <div className="col">
                <span className="text-black strong text-size-3">{profile_details.stats.breakdown.daily}</span><p className="text-muted">daily</p>
              </div>
              <div className="col">
                <span className="text-black strong text-size-3">{profile_details.stats.breakdown.weekly}</span><p className="text-muted">weekly</p>
              </div>
              <div className="col">
                <span className="text-black strong text-size-3">{profile_details.stats.breakdown.monthly}</span><p className="text-muted">monthly</p>
              </div>
              <div className="col">
                <span className="text-black strong text-size-3">{profile_details.stats.breakdown.yearly}</span><p className="text-muted">yearly</p>
              </div>
            </div>

            <hr className="py-3"/>

            <div className="m-5">
              <h3 className="my-3">Developer section</h3>
              <div className="">
                <p className="">Your API Key: </p>
                <pre className="small">
                  <code className="">{profile_details.dev.api_key}</code>
                </pre>
                <div className="text-muted text-size-2">
                  * Documentation available on request * ... <a href="mailto:info@bytebeacon.com" className="text-primary">Get in touch with us</a> if you have integration ideas and would like to use our API.
                </div>
              </div>
            </div>

            </div>
          </div>
        )}
  
        </div>
        
      </SideNavLayout>
    )
  }
}

export default withRouter(Page);
