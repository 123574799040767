// import React from 'react';
import React, { useEffect, useState } from "react";
// import {Helmet} from "react-helmet";

import TopNav from 'component/navigation/topbar';
import SideNav from 'component/navigation/sidebar';
import BottomNav from 'component/navigation/bottom';


export default function Layout(props){
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  return (
    <div>
      {/* <Helmet>
        <meta property="og:title" content="Track recurring events and get the encouragement to stick to the schedule that leads you to a better you" />
        <meta property="og:type" content="product" />
        <meta property="og:url" content="https://bytetrack-app.bytebeacon.com" />
        <meta property="og:image" content="https://bytetrack-app.bytebeacon.com/logo.png" />
        <meta property="og:site_name" content="ByteTrack | Track recurring activities" />
        <meta property="og:description" content="Track recurring events and get the encouragement to stick to the schedule that leads you to a better you!" />
      </Helmet> */}
      {isMobile ? (
        <div id={props.id} className="page-wrapper">
          <TopNav />
          <BottomNav>
            <div className="py-3">
              {props.children}
            </div>
          </BottomNav>  
        </div>
      ):(
        <div id={props.id} className="page-wrapper">
          {/* <Helmet>
          <script data-name="BMC-Widget" src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js" data-id="bytebeacon" data-description="Consider supporting the dev/ infra costs" data-message="" data-color="#FF813F" data-position="right" data-x_margin="18" data-y_margin="18"></script>
          </Helmet> */}
          <TopNav />
          <SideNav eventKey={props.eventKey}>
            {props.children}
          </SideNav>  
        </div>
      )}
    </div>
  );

}


// export default class Layout extends React.Component {
//   render (){
//     return (
//       <div id={this.props.id} className="page-wrapper">
//         <TopNav />
//         <SideNav>
//           <div className="py-3">
//             {this.props.children}
//           </div>
//         </SideNav>  
//       </div>
//     );
  
//   }
// }
