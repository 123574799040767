import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch } from 'react-router-dom';

// import {createBrowserHistory} from 'history';
// import createBrowserHistory from 'history/createBrowserHistory';
import {createBrowserHistory} from 'history';
import ReactGA from 'react-ga';

import LoginPage from './page/public/login';
import SupportPage from './page/public/support';
import ReachPage from './page/public/reach';
import WidgetSummaryRadarPage from './page/public/widgets/summary_radar';

// import PublicBuildPage from './page/public/build';
// import PublicCreatePage from './page/public/view';
// import PublicSchedulePage from './page/public/view';

import CreatePage from './page/protected/create';
import EditPage from './page/protected/edit';
import ViewPage from './page/protected/view';

import SettingsPage from './page/protected/settings';
import AnalyticsPage from './page/protected/analytics';
import AnalyticsDetailsPage from './page/public/widgets/analytics_details';
// import HistoryPage from './page/protected/history';
import SchedulePage from './page/protected/schedule';
import ActivePage from './page/protected/active';

import GhostPage from './page/protected/ghost';

// import DashboardTopPage from './page/protected/dashboard';
import NoMatchPage from './page/generic/no_match';

// saveDemoToken
import { hasStoredToken, getUserId, savePublicToken } from './utils/authentication';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';


// import './styles/animate.min.css';
import './styles/alertify.min.css';
// Be sure to include styles at some point, probably during your bootstraping
// import '@trendmicro/react-sidenav/dist/react-sidenav.css';
// import 'rsuite/dist/styles/rsuite-dark.css';
// import 'rsuite/styles/index.less'; // or 'rsuite/dist/rsuite.min.css'
import 'rsuite/dist/rsuite.min.css'

// import 'rsuite/lib/styles/themes/dark/index.less';
// import './styles/custom-theme.less';
import './styles/bootstrap.min.css';
// reordering this causes the creation widget to fail


const history = createBrowserHistory();


history.listen(location => {
  // const on_sites = (location.pathname.match(/sites/gi) || []).length;
  // const on_instructions = (location.pathname.match(/instructions/gi) || []).length;
  // const on_no_scroll_page = (on_sites > 0 || on_instructions > 0);
  const on_no_scroll_page = false;
  if (on_no_scroll_page){

  } else {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  
  ReactGA.set({ userId: getUserId(), page: location.pathname });
  ReactGA.pageview(location.pathname); 
});


const trackingId = process.env.REACT_APP_GA_CODE;  
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname);


// function pushDemo(){
//   saveDemoToken();
//   return (<ActivePage />);
// }
function pushPublic(){
  savePublicToken();
  return (<ActivePage />);
}

ReactDOM.render(
  <Router history={history}>
    <Switch>

      {/* <Route exact path='/public/build' component={PublicBuildPage} />
      <Route exact path='/public/:view_xid/create' component={PublicCreatePage} />
      <Route exact path='/public/:view_xid/active' component={PublicActivePage} />
      <Route exact path='/public/:view_xid/schedule' component={PublicSchedulePage} /> */}

      <Route exact path='/create' render={() => (hasStoredToken()? (<CreatePage />) : (<LoginPage />))}/>
      <Route exact path='/edit/:template_xid' render={() => (hasStoredToken()? (<EditPage />) : (<LoginPage />))}/>
      <Route exact path='/view/:template_xid' render={() => (hasStoredToken()? (<ViewPage />) : (<LoginPage />))}/>
      <Route exact path='/view/:template_xid' render={() => (hasStoredToken()? (<ViewPage />) : (<LoginPage />))}/>
      <Route exact path='/widgets/:user_xid/radar_summary' component={WidgetSummaryRadarPage} />
      <Route exact path='/widgets/:user_xid/analytics/:template_xid' render={() => (hasStoredToken()? (<AnalyticsDetailsPage />) : (<LoginPage />))}/>
      
      <Route exact path='/active' render={() => (hasStoredToken()? (<ActivePage />) : (<LoginPage />))}/>
      <Route exact path='/settings' render={() => (hasStoredToken()? (<SettingsPage />) : (<LoginPage />))}/>
      <Route exact path='/analytics' render={() => (hasStoredToken()? (<AnalyticsPage />) : (<LoginPage />))}/>
      {/* <Route exact path='/history' render={() => (hasStoredToken()? (<HistoryPage />) : (<LoginPage />))}/> */}
      <Route exact path='/schedule' render={() => (hasStoredToken()? (<SchedulePage />) : (<LoginPage />))}/>

      <Route exact path='/ghost' render={() => (hasStoredToken()? (<GhostPage />) : (<LoginPage />))}/>
      {/* <Route exact path='/topbar' render={() => (hasStoredToken()? (<DashboardTopPage />) : (<Redirect to={"/?dest=" + window.location.pathname}/>))}/> */}
      {/* <Route exact path='/sidebar' render={() => (hasStoredToken()? (<DashboardSidePage />) : (<Redirect to={"/?dest=" + window.location.pathname}/>))}/> */}

      {/* <Route exact path='/demo' render={() => (pushDemo())}/> */}
      <Route exact path='/demo/:date' render={() => (pushPublic())}/>
      <Route exact path='/public/:date' render={() => (pushPublic())}/>
      <Route exact path='/early/:date' render={() => (pushPublic())}/>
      {/* <Route exact path='/open' render={() => (pushPublic())}/> */}
      {/* <Route exact path='/common' render={() => (pushPublic())}/> */}

      <Route exact path='/login' render={() => (hasStoredToken()? (<ActivePage />) : (<LoginPage />))}/>
      <Route exact path='/support' render={() => (hasStoredToken()? (<SupportPage />) : (<LoginPage />))}/>
      <Route exact path='/reach' render={() => (hasStoredToken()? (<ReachPage />) : (<LoginPage />))}/>
      <Route exact path='/' render={() => (hasStoredToken()? (<ActivePage />) : (<LoginPage />))}/>

      <Route component={NoMatchPage} />

    </Switch>
  </Router>
  , document.getElementById('page')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
