import React from 'react';
import { withRouter } from 'react-router-dom';
import { Sidenav, Nav, Navbar } from 'rsuite';
import { Icon } from '@rsuite/icons';


import { deleteToken, isDemoAccount, isPublicAccount } from 'utils/authentication';


const NavToggle = ({ expand, onChange, logout }) => {
  return (
    <Navbar className="nav-toggle bg-dark">
      <Navbar.Body>
        <Nav>
          <Nav.Item eventKey="logout" onClick={logout}>
            <Icon className="" style={{}} as={SignOutSvg} /> {expand ? ' Logout' : ''}
          </Nav.Item>
        </Nav>

        <Nav pullRight>
          <Nav.Item className="bg-dark" onClick={onChange} style={{ width: 50, textAlign: 'center' }}>
            <Icon style={{color: 'white'}} as={expand ? LeftSvg : RightSvg} />
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};


const LeftSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"/>
  </svg>
));


const RightSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/>
  </svg>
));


const PlusSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d='M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z' />
  </svg>
));


const SignOutSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z"/>
  </svg>
));

const CalanderSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm1 11.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z"/>
  </svg>
));

const RetentionSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M17 1c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-12 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm13 5v10h-16v-10h16zm2-6h-2v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-8v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-2v18h20v-18zm4 3v19h-22v-2h20v-17h2zm-17 7h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"/>
  </svg>
));

const BarChartSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M5 19h-4v-8h4v8zm6 0h-4v-18h4v18zm6 0h-4v-12h4v12zm6 0h-4v-4h4v4zm1 2h-24v2h24v-2z"/>
  </svg>
));

const CogSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M24 14.187v-4.374c-2.148-.766-2.726-.802-3.027-1.529-.303-.729.083-1.169 1.059-3.223l-3.093-3.093c-2.026.963-2.488 1.364-3.224 1.059-.727-.302-.768-.889-1.527-3.027h-4.375c-.764 2.144-.8 2.725-1.529 3.027-.752.313-1.203-.1-3.223-1.059l-3.093 3.093c.977 2.055 1.362 2.493 1.059 3.224-.302.727-.881.764-3.027 1.528v4.375c2.139.76 2.725.8 3.027 1.528.304.734-.081 1.167-1.059 3.223l3.093 3.093c1.999-.95 2.47-1.373 3.223-1.059.728.302.764.88 1.529 3.027h4.374c.758-2.131.799-2.723 1.537-3.031.745-.308 1.186.099 3.215 1.062l3.093-3.093c-.975-2.05-1.362-2.492-1.059-3.223.3-.726.88-.763 3.027-1.528zm-4.875.764c-.577 1.394-.068 2.458.488 3.578l-1.084 1.084c-1.093-.543-2.161-1.076-3.573-.49-1.396.581-1.79 1.693-2.188 2.877h-1.534c-.398-1.185-.791-2.297-2.183-2.875-1.419-.588-2.507-.045-3.579.488l-1.083-1.084c.557-1.118 1.066-2.18.487-3.58-.579-1.391-1.691-1.784-2.876-2.182v-1.533c1.185-.398 2.297-.791 2.875-2.184.578-1.394.068-2.459-.488-3.579l1.084-1.084c1.082.538 2.162 1.077 3.58.488 1.392-.577 1.785-1.69 2.183-2.875h1.534c.398 1.185.792 2.297 2.184 2.875 1.419.588 2.506.045 3.579-.488l1.084 1.084c-.556 1.121-1.065 2.187-.488 3.58.577 1.391 1.689 1.784 2.875 2.183v1.534c-1.188.398-2.302.791-2.877 2.183zm-7.125-5.951c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.762 0-5 2.238-5 5s2.238 5 5 5 5-2.238 5-5-2.238-5-5-5z"/>
  </svg>
));

const HeartSvg = React.forwardRef((props, ref) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="currentColor"  ref={ref}>
    <path d="M12 21.593c-5.63-5.539-11-10.297-11-14.402 0-3.791 3.068-5.191 5.281-5.191 1.312 0 4.151.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447 2.54 0 5.274 1.621 5.274 5.181 0 4.069-5.136 8.625-11 14.402m5.726-20.583c-2.203 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248-3.183 0-6.281 2.187-6.281 6.191 0 4.661 5.571 9.429 12 15.809 6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181"/>
  </svg>
));


class SideNavBar extends React.Component {

  constructor(props) {
    super();
    
    this.state = {
      expanded: false,
      activeKey: props.eventKey,
    };  

    this.handleToggle = this.handleToggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount(){
    // this.setState(this.props.location.state);
    this.setState({activeKey: this.props.eventKey});
    // console.log(this.state);
  }

  handleToggle() {
    this.setState({expanded: !this.state.expanded});
  }

  handleSelect(eventKey) {
    if (eventKey === 'logout'){return;}

    
    const to = '/' + eventKey;
    this.props.history.push(to);
    this.setState({activeKey: eventKey});

    // if (window.location.pathname !== to) {
    //   this.props.history.push({
    //     pathname: to,
    //     // search: '?query=abc',
    //     state: this.state,
    //   });
    //   this.props.location.pathname = to;
    // }
  }

  logout () {
    deleteToken();
    this.props.history.push('/');
  }

  render() {
    var contentStyle = {
      position: "relative",
      overflow: "hidden",
      // transition: "all .15s",
      padding: "0 20px",
      marginLeft: this.state.expanded? "250px": "56px",
    }

    const sidenavStyle = {
      position: "fixed",
      height: "100%",
      top: (isDemoAccount() || isPublicAccount())? "67px": "49px",
      width: this.state.expanded? "250px": "56px",
    }

    const pushBottom = {
      position: "absolute",
      bottom: (isDemoAccount() || isPublicAccount())? "67px": "49px",
      width: "100%",
    }

    const { activeKey, expanded } = this.state;


    return (
      <div className="">

        <div className="bg-dark" style={sidenavStyle}>
          <Sidenav
            appearance="subtle"
            // className="bg-dark" 
            expanded={expanded}
            activeKey={activeKey}
            onSelect={this.handleSelect}
            // collapsible
          >
            <Sidenav.Body>
              <Nav >
                <Nav.Item eventKey="create" icon={<Icon className="" style={{ }} as={PlusSvg}  />}>
                  Create
                </Nav.Item>
                <Nav.Item eventKey="active" icon={<Icon className="" style={{ }} as={CalanderSvg}  />}>
                  Actively tracking
                </Nav.Item>
                <Nav.Item eventKey="schedule" icon={<Icon className="" style={{ }} as={RetentionSvg}  />}>
                  Schedule
                </Nav.Item>
                <Nav.Item eventKey="analytics" icon={<Icon className="" style={{ }} as={BarChartSvg}  />}>
                  Analytics
                </Nav.Item>
                <Nav.Item eventKey="settings" icon={<Icon className="" style={{ }} as={CogSvg}  />}>
                  Settings
                </Nav.Item>
                <Nav.Item eventKey="support" icon={<Icon className="text-quinary pulse" style={{ }} as={HeartSvg}  />}>
                  Support Us
                </Nav.Item>
              </Nav>
            </Sidenav.Body>
          </Sidenav>

          <div className="" style={pushBottom}>
            <NavToggle className="" expand={expanded} onChange={this.handleToggle} logout={this.logout.bind(this)} />
          </div>
          
        </div>
        
        <div className="page-content py-5" style={contentStyle}>
          <div className={" " + ((isDemoAccount() || isPublicAccount())? ' my-3 ': ' ')}>
            {this.props.children}
          </div>
        </div>

      </div>

    );
  }
}


export default withRouter(SideNavBar);
