import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { 
  // Progress, 
  Avatar, 
  Toggle 
} from 'rsuite';
import { Icon } from '@rsuite/icons';

import API, {handleError} from 'utils/xhr';
import {Rainbow} from 'utils/color';

import {ContainerSection} from 'component/reuse/containers';

import SideNavLayout from 'component/page/side_nav';
// import AddNewCard from 'component/reuse/card/add';
import ActiveCard from 'component/reuse/card/active';
import {LoaderRipple} from 'component/loader';
import {SectionTitle, ParaTitle} from 'component/reuse/title';
import { hasTimezone } from 'utils/authentication';
// import AdSense from 'component/reuse/common/adsense';



class Page extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      goals: null,
      hideComplete: true,
    };
  }

  componentDidMount(){
    const url = '/habits';
    API.get(url).then(response => {
      this.setState({goals: response.data.data.goals,});
    }, (error) => {
      handleError(error, this.props.history, window.location.pathname);
    });
  }

  onHideComplete(){
    const state = this.state;
    state.hideComplete = !state.hideComplete;
    this.setState(state);
  }

  render (){
    const is_mobile = window.innerWidth < 640;
    return (
      <SideNavLayout id="dashboard-page" eventKey="active" >
        <SectionTitle title="Active targets" icon_class="fa fa-calendar-check" /> 
        
        {/* <div className="row">

          <div className={" m-0 p-0 " + ((is_mobile)? ' col-12 ' : ' col-10 ' )}>

            <div className="d-flex w-100 justify-content-between">
              {(hasTimezone()? (
                <div className="pl-2 d-flex align-items-center">
                  <a href="/support" className="text-quinary pulse">Support us!</a>
                </div>
              ): (
                <small className="alert alert-warning">
                  <div className="text-black"><strong><Link to="/settings" className=" text-black">Set your timezone</Link></strong> for the correct view!</div>
                </small>
              ))}
              <div className={"pl-2 text-center" + ((is_mobile)? '': ' dark-bl-1-1 ')}>
                <Toggle className="text-white" checkedChildren="Hide" unCheckedChildren="Show" onChange={this.onHideComplete.bind(this)}  />
                <div className="text-muted">completed</div>
              </div>
            </div>

            {(this.state.goals === null)? (<LoaderRipple />): (
              <DisplayGoals goals={this.state.goals} hideComplete={this.state.hideComplete} />
            )}
          </div>

          {(is_mobile)? (''): (
          <div className={" m-0 p-0 d-none d-md-block " + ((is_mobile)? ' ' : ' col-2 ' )}>
            <div className="card p-1 bg-light ml-1">
              <AdSense />
            </div>
          </div>
          )}

        </div> */}

<div className="row">
<div className={" m-0 p-0 col-12 " + ((is_mobile)? '  ' : '  ' )}>
  <div className="d-flex w-100 justify-content-between">
    {(hasTimezone()? (
      <div className="pl-2 d-flex align-items-center">
        {/* <a href="/support" className="text-quinary pulse">Support us!</a> */}
      </div>
    ): (
      <small className="alert alert-warning">
        <div className="text-black"><strong><Link to="/settings" className=" text-black">Set your timezone</Link></strong> for the correct view!</div>
      </small>
    ))}
    <div className={"pl-2 text-center" + ((is_mobile)? '': ' ')} data-tip={'Completed tasks'}>
      <Toggle className="text-white" checkedChildren="Hide" unCheckedChildren="Show" onChange={this.onHideComplete.bind(this)}  />
      {/* <div className="text-muted">completed</div> */}
    </div>
  </div>
  {(this.state.goals === null)? (<LoaderRipple />): (
    <DisplayGoals goals={this.state.goals} hideComplete={this.state.hideComplete} />
  )}
</div>
</div>
      </SideNavLayout>
    )
  }
}


function DisplayGoals(props){
  const goals = props.goals || [];
  // const is_mobile = window.innerWidth < 1200;

  if (goals.length === 0){
    return (
      <div className="my-3">
        You don't have any goals set at this moment! Start by <Link to="/create">creating one</Link>.
      </div>
    )
  }

  return (
    <React.Fragment>
      {goals.map(function(goal, idx){
        return (
          <React.Fragment key={"_"+idx}>
            {/* {(idx === 2)? (<OrbbAds />) : ''} */}
            {/* {((idx === 2) && is_mobile)? (<div className="my-2"><AdSense mode="h"/></div>): ''} */}
            <TargetSection key={"_"+idx} detail={goal} hideComplete={props.hideComplete} />
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}


function TargetSection(props){
  // const { Line } = Progress;
  const detail = props.detail;
  const hideComplete = props.hideComplete;

  var color_percent = new Rainbow();
  color_percent.setNumberRange(0, 100);
  // color_percent.setSpectrum('#A1F218', '#F2D618', '#F26918');
  // color_percent.setSpectrum('#A1F218', '#A1F218', '#A1F218', '#F2D618', '#F2D618', '#F26918');
  // color_percent.setSpectrum('#18bc9c', '#18bc9c', '#18bc9c', '#f0ad4e', '#f0ad4e', '#e74c3c', '#e74c3c');
  // color_percent.setSpectrum('#18bc9c', '#f0ad4e', '#e74c3c');
  // color_percent.setSpectrum('#18bc9c', '#f0ad4e', '#e74c3c');
  // color_percent.setSpectrum('#666666','#999999','#bbbbbb', '#ff0000');
  color_percent.setSpectrum('#dddddd', '#bbbbbb', '#bbaaaa', '#ff0000');
  // color_percent.setSpectrum('#18bc9c', '#f0ad4e', '#ffacac');
  // color_percent.setSpectrum('#ffe0e0', '#eea2a2', '#ff0000');
  // color_percent.setSpectrum('#fff0f0',  '#e41a1a', '#e40000');

  var cnt_incompleted = 0;
  detail.items.forEach(function(item, idx){
    const complete_percentage = parseInt((item.activity.count.current/item.activity.count.target) * 100);
    const is_completed = complete_percentage >= 100;
    if (!is_completed){cnt_incompleted += 1;}
    return;
  })
  const cnt_total = detail.items.length;

  return (
    <div className="my-3 light-bg-1">
      <ReactTooltip effect="solid"/>
      <ContainerSection className="">
      {/* <div className="card p-1 bg-light my-3"> */}

        <div className="d-flex w-100 justify-content-between">
          <ParaTitle>
            <div 
              className=" " 
              data-tip={detail.range.text}
              // data-tip={detail.range.start + " - " + detail.range.end}
              // style={{backgroundColor: color_percent.colourAt(detail.remaining.percent)}}
            >
              <span className="mr-1">{detail.title}: </span>

              <span className=" text-black-8 text-center align-self-centera" >
                <small style={{color: color_percent.colourAt(detail.remaining.percent)}} >{detail.remaining.text} for</small>

                <span className="badge text-white mx-2" 
                  style={{backgroundColor: color_percent.colourAt(detail.remaining.percent)}}
                >
                  {((hideComplete)? ""+cnt_incompleted: ""+cnt_total)}
                </span> 

                <small style={{color: color_percent.colourAt(detail.remaining.percent)}}>tasks </small>
              </span>

            </div>
          </ParaTitle>
        </div>

        <div className="progress my-1" style={{height: '5px', width: '50%', backgroundColor: 'white'}}>
          <div className="progress-bar" role="progressbar" 
            style={{
              width: detail.remaining.percent+'%', 
              backgroundColor: color_percent.colourAt(detail.remaining.percent)
            }} 
          ></div>
        </div>

        <div className="row flex-row flex-nowrap" style={{"overflowX": "auto"}}>

          {(detail.items.length === 0)? (
            <div className="p-2 m-2"><Avatar circle size="lg"><Icon icon="question" /></Avatar><p className="m-2">No targets! Start by creating one. </p></div>
          ) : ('')}

          {detail.items.map(function(item, idx){
            return (<ActiveCard key={"_"+idx} item={item} hideComplete={hideComplete} />)
          })}
        </div>

      </ContainerSection>
    </div>
  )
}


export default withRouter(Page);
