import React from 'react';
import { withRouter} from 'react-router-dom';

import SideNavLayout from 'component/page/side_nav';
// import {SectionTitle} from 'component/reuse/title';
import SocialComponent from 'component/reuse/social';
import BBPromo from 'component/content/bb_promo';
import { PROFILE } from "component/constant";


class Page extends React.Component {
  render (){

    const is_mobile = window.innerWidth < 640;
    const spacing = is_mobile? 'py-3': 'my-2';

    return (
      <SideNavLayout id="reach-page">

        <div className="mx-3 my-5 pb-2 bg-light border p-2 ">

          <h5 className="my-2 pb-2 text-size-8">Have feedback for us?</h5>

          <div className="my-3 pb-3">
            <div className={" " + spacing}>
              <div className={" " + spacing}>
                Connect with us on our social channels:
              </div>
              <div className={" " + spacing}>
              <SocialComponent contact={PROFILE.contact} enlarge={true}  color='#25ADCB'/>
              </div>
            </div>
          </div>

          <div className="my-3 pb-3">
            <div className={" " + spacing}>
              You can also reach us <a href="mailto:info@bytebeacon.com" className="text-primary">through email</a>.
            </div>
          </div>

        </div>

        <div className="mx-3 my-5 pb-2 bg-light border p-2  ">
        <BBPromo />
        </div>

                
      </SideNavLayout>
    )
  }
}

export default withRouter(Page);
