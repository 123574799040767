import React from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';

import Logo from 'component/assets/logo';
// import Gumroad from 'component/reuse/gumroad';
import { hasStoredToken, deleteToken, isDemoAccount, isPublicAccount } from 'utils/authentication';


class Nav extends React.Component {

  constructor (props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout () {
    deleteToken();
    this.props.history.push('/');
  }

  render () {
    // const is_mobile = window.innerWidth < 640;
    return(
      <div className="fixed-top ">

        {(isDemoAccount()? (
          <div className="bg-danger text-white text-center py-0">
            <span className="strong">Demo account! </span>
            <a href="/" className="mx-1 text-white" onClick={this.logout}>
              Logout and login with your account to track your activities
            </a>
          </div>
        ):(''))}
        {(isPublicAccount()? (
          <div className="bg-primary text-white text-center py-0">
            <span className="strong">Public account: </span>
            This account is reset frequently! 
            <a href="/" className="mx-1 text-white" onClick={this.logout}>
              Create your own account for customized planning
            </a>
          </div>
        ):(''))}
      <nav className={"navbar navbar-expand-lg p-0 " + ((hasStoredToken())? (' bg-dark '): (' bg-white box-shadow ')) }>

        <div className="navbar-header">
        
          {(hasStoredToken())? (
            <Link to="/" className="navbar-brand text-spacing-2 mr-2">
              {/* <img style={{height: ((is_mobile)? "25px": "40px")}} className="hero mx-2" src="/logo.svg" alt="logo svg" /> */}
              <span className="text-white m-2"><Logo name="logo" size={40} /></span>
              <span className="text-white">Byte</span><span className="text-primary">Track</span>
            </Link>  
          ): (
            <Link to="/" className="navbar-brand text-spacing-2 mr-2">
              <span className="text-primary mx-2"><Logo name="logo" size={25} /></span>
              <span className="text-black-2">Byte</span><span className="text-primary">Track</span>
            </Link>  
          )}
        </div>

        <button className="navbar-toggler btn btn-primary text-white mr-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
            </li>
          </ul>

          { hasStoredToken() ? (
            <ul className="navbar-nav my-0 my-lg-0">
              {/* <li className="nav-item">
                <NavLink exact activeClassName="highlight-primary-1" className=" text-white btn btn-rounded mx-1 uppercase" to="/billing"><span className="fa fa-coins mr-2"></span>Billing</NavLink>
              </li> */}
              {/* <li className="nav-item">
                <Gumroad />
              </li> */}

              <li className="nav-item">
                <NavLink exact activeClassName="bg-white text-primary" className=" text-white btn btn-rounded mx-1 uppercase" to="/reach"><span className="fa fa-envelope mr-2"></span>Reach out</NavLink>
              </li>

              <li className="nav-item">
                <NavLink exact activeClassName="bg-white text-black" className=" quinary-text-1 btn btn-rounded mx-1 uppercase" to="/support"><span className="quinary-text-1 fa fa-heart mr-2 pulse"></span>Support us</NavLink>
              </li>

              <li className="nav-item">
                <a href="https://bytetrack.bytebeacon.com" className="text-white btn btn-rounded mx-1 uppercase"><span className="fa fa-home mr-2"></span>Landing</a>
                {/* <NavLink exact activeClassName="highlight-primary-1" className=" text-white btn btn-rounded mx-1 uppercase" to="/billing"><span className="fa fa-coins mr-2"></span>Billing</NavLink> */}
              </li>
              {((isDemoAccount() || isPublicAccount())? (
                <React.Fragment>
                <li className="nav-item">
                  <a href="/" className="text-white btn btn-rounded mx-1 uppercase text-primary" onClick={this.logout}>
                    <span className="fa fa-plus mr-2"></span>Build my plan
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/" className="text-white btn btn-rounded mx-1 uppercase text-danger " onClick={this.logout}>
                  <span className="fa fa-power-off mr-2"></span>Close Demo
                  </a>
                </li>
              </React.Fragment>
              ):(''))}

              <li className="nav-item dropdown">
                <Link className=" text-white  btn mx-1 uppercase dropdown-toggle" to="/" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="fa fa-cog mr-2"></span>Account
                </Link>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown1">
                  <Link className="dropdown-item" to="/create">Create target</Link>
                  <Link className="dropdown-item" to="/active">Active targets</Link>
                  <Link className="dropdown-item" to="/schedule">Schedule</Link>
                  <Link className="dropdown-item" to="/analytics">Analytics</Link>
                  <Link className="dropdown-item" to="/settings">Settings</Link>
                  <hr className="my-2"/>
                  <Link className="dropdown-item" to="/support">Support Us</Link>
                  <a href="https://bytetrack.bytebeacon.com" className="dropdown-item">ByteTrack Landing</a>
                  <hr className="my-2"/>
                  <button className="dropdown-item" onClick={this.logout}>
                    <span className="fa fa-power-off mr-2"></span>Logout
                  </button>
                </div>
              </li>

            </ul>
          ): (
            <ul className="navbar-nav my-0 my-lg-0">
              {/* <li className="nav-item">
                <Link to="/login" className="btn btn-primary btn-rounded text-white text-spacing-2 m-2 uppercase"><i className="fa fa-paper-plane mr-2"></i>Start free</Link>
              </li> */}
              {/* <li className="nav-item">
                <Link to="/login" className="btn btn-outline-primary btn-rounded text-spacing-2 m-2 uppercase"><i className="fa fa-sign-in-alt mr-2"></i>Login</Link>
              </li> */}
            </ul>
          )}

        </div>
      </nav>
      </div>

    )
  }
}



export default withRouter(Nav);
