import React from 'react';
import { withRouter} from 'react-router-dom';

import BBPromo from 'component/content/bb_promo';
import SideNavLayout from 'component/page/side_nav';
// import {SectionTitle} from 'component/reuse/title';
import SocialComponent from 'component/reuse/social';
import { PROFILE } from "component/constant";

import Gumroad from 'component/reuse/common/gumroad';


// class Page extends React.Component {
  // render (){

function Page (){

    const is_mobile = window.innerWidth < 640;
    const spacing = is_mobile? 'py-3': 'my-2';

    return (
      <SideNavLayout id="support-page">
        {/* <SectionTitle title="Support Us" /> */}

        <div className="">

        <div className="mx-3 my-5 pb-2 bg-light border p-2 ">
          <h5 className="my-2 pb-2 text-size-8">Our focus is on delivering value:</h5>

          <div className={" " + spacing}>
            We feel pricing should not be a blocker, and we wish to keep this platform open to all.
          </div>
          <div className={" " + spacing}>
            Our priority is to deliver true value and benefits to our users by providing them with the motivation to make and track the changes they wish to see in themselves.
          </div>
          <div className={" " + spacing}>
            The delivered value can be different for each user. Because of this, we hope our users will choose to support us based on how they value the results.
          </div>
        </div>

        <div className="mx-3 my-5 pb-2 bg-light border p-2  ">
          <h5 className="my-2 pb-2 text-size-8">If you're in a position to help:</h5>

          <div className="my-3 pb-3">
            <i className="my-1 fa fa-caret-right mr-2"><span className="mr-2"></span>Financial help</i>
            <div className={" " + spacing}>
              <span className="mr-2 ">If you're in a position to help us financially!</span>

              <div className="my-3 text-center">
              <div className="">
              <Gumroad />
              </div>

              <div className="my-2"> or </div>

              <div className="">
              <a  href="https://www.patreon.com/bytebeacon" className="">
                <img style={{ width: '120px'}} src="/static/images/become_a_patron_button.png" alt="" className=""/>
              </a>
              </div>

               <div className="my-2"> or </div>

              <div className="">
              <a  href="https://www.buymeacoffee.com/bytebeacon" className="">
              <img style={{ width: '120px'}} src="/static/images/bmac.png" alt="" className=""/>
              </a>
              </div>
              </div>

              
            </div>
          </div>

          <div className="my-3 pb-3">
            <i className="my-1 fa fa-caret-right mr-2"><span className="mr-2"></span>Social help</i>
            <div className={" " + spacing}>
              <div className={" " + spacing}>
                Connect with us on our social channels and help spread the word to others who can benefit from this offering.
              </div>
              <div className={" text-center " + spacing}>
              <SocialComponent contact={PROFILE.contact} enlarge={true}  color='#FF813F'/>
              </div>
            </div>
          </div>

          <div className="my-3 pb-3">
            <i className="my-1 fa fa-caret-right mr-2"><span className="mr-2"></span>Other ways</i>
            <div className={" " + spacing}>
              If you'd like to see us grow and have ideas on how you can help us (partnership / co-branding etc ... ), <a href="mailto:info@bytebeacon.com" className="text-quinary">please do reach out!</a>
            </div>
          </div>

        </div>

        <div className="mx-3 my-5 pb-2 bg-light border p-2  ">
        <BBPromo />
        </div>

        </div>   
      </SideNavLayout>
    )
  // }
}

export default withRouter(Page);
