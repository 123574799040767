import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import API, {handleError} from 'utils/xhr';

import SideNavLayout from 'component/page/side_nav';
import {DomainBadge} from 'component/reuse/displays';
import {SectionTitle } from 'component/reuse/title';

import {ActivityBuilder} from 'component/reuse/activity_builder';


// class SmartInput extends React.Component {
//   constructor(props){
//     super(props);
//     this.state = {
//       text: '',
//       formatted_text: '',
//       created_template: null,
//     }
//   }

//   onInput(e){
//     const state = this.state;
//     state.text = e.target.value;
//     state.formatted_text = this.format_text(state.text);
//     state.created_template = generate_new_activity_template(state.text);
//     this.setState(state)
//   }

//   componentDidUpdate(){

//   }

//   format_text(text){
//     const words = text.split(",");

//     var formatted_words = [];
//     for (var i=0; i<words.length; i++){
//       var curr_word = words[i];
//       if (i === 0){
//         curr_word = '<div><small className="text-muted">Activity: </small><span className="strong text-info">' +curr_word+ '</span></div>';
//       } 
//       if (i === 1){
//         curr_word = '<div><small className="text-muted">Count: </small><span className="strong text-danger">' +curr_word+ '</span></div>';
//       }
//       if (i === 2){
//         curr_word = '<div><small className="text-muted">Frequency: </small><span className="strong text-success">' +curr_word+ '</span></div>';
//       }
//       formatted_words.push(curr_word)
//     }
//     return '<div className="card p-3">' + formatted_words.join(' ') + '</div>';
//   }

//   render (){

//     const ref_template = this.state.created_template;
//     // const ref_template = this.props.ref_template;
    
//     return (
//       <div className="m-2 p-2 ">
//         <div className="row">
//           <div className="col-8">
//             <div className="form-group ">
//               <div className="">
//               <label className="text-muted">
//                 {"Track: "}
//                 <TextLoop interval={3000}>
//                   <span className="">drink water, 8 glasses, daily</span>
//                   <span className="">call, 1 friend, weekly</span>
//                   <span className="">write, 4 blogs, monthly</span>
//                   <span className="">family vacation, 2 times, yearly</span>
//                 </TextLoop>
//               </label>
//               <input 
//               type="text"
//               className="form-control my-2" 
//               onChange={this.onInput.bind(this)} 
//               value={this.state.text}
//               placeholder="e,g: workout, 3 times, weekly"
//               />
//               <textarea className="form-control my-2" name="" id="" cols="30" rows="10" placeholder="Add personal notes in here. Drop in links to articles, videos, quotes that motivate you on this activity">

//               </textarea>
//               {/* <div 
//                 contentEditable={true}
//                 suppressContentEditableWarning={true} 
//                 className="form-control" 
//                 dataText="workout, 3 times, weekly"
//                 onInput={this.onInput.bind(this)} 
//               /> */}
//               </div>
//             </div>
//           </div>
//           <div className="col-4">
//             <div 
//               dangerouslySetInnerHTML={{__html: this.state.formatted_text}}
//               className="m-2 p-2" 
//             ></div>

//             <ActivityTemplateCardView template={ref_template} hide_edit/>

//           </div>
//         </div>
//       </div>
//     )
//   }
// }



class Page extends React.Component {

  constructor(props){
    super(props)
    const values = qs.parse(this.props.location.search);
    this.state = {
      suggestion_info: null,
      edit_template: null,
      frequency: values.frequency? values.frequency: null,
    }
  }

  componentDidMount(){
    const url = '/suggestions';

    API.get(url).then(response => {
      this.setState({suggestion_info: response.data.data,});
    }, (error) => {
      handleError(error);handleError(error, this.props.history, window.location.pathname);
    });
  }

  onEdit(e){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    // const reference_template = null;
    for (var i=0; i<this.state.suggestion_info.suggestions.length; i++){
      const current_sugg = this.state.suggestion_info.suggestions[i].template;
      if (current_sugg.xid === e.currentTarget.id){
        const state = this.state;
        state.edit_template = current_sugg;
        this.setState(state)
        break;
      }
    }
  }

  render (){
    return (
      <SideNavLayout id="create-page" eventKey="create">
        <SectionTitle title="Create target"  icon_class="fa fa-plus" />

        <ActivityBuilder frequency={this.state.frequency} ref_template={this.state.edit_template} />

        <hr className="my-5"/>

        <PopularSuggestions suggestion_info={this.state.suggestion_info} onEdit={this.onEdit.bind(this)} />

      </SideNavLayout>
    )
  }
}



class PopularSuggestions extends React.Component {

  render (){

    const suggestion_info = this.props.suggestion_info;

    if (suggestion_info === undefined || suggestion_info === null || (Object.keys(suggestion_info).length === 0 && suggestion_info.constructor === Object)){
      return '';
    }

    const suggestion_cateroy_map = {};
    for ( var k=0; k<suggestion_info.categories.length; k++){
      suggestion_cateroy_map[suggestion_info.categories[k].xid] = suggestion_info.categories[k];
      suggestion_cateroy_map[suggestion_info.categories[k].xid].suggestions = [];
    }
    
    for ( var i=0; i<suggestion_info.suggestions.length; i++){
      for (var j=0; j<suggestion_info.suggestions[i].category_xids.length; j++){
        const cat_id = suggestion_info.suggestions[i].category_xids[j];
        if (suggestion_cateroy_map[cat_id].suggestions === undefined){
          suggestion_cateroy_map[cat_id].suggestions = [];
        }
        suggestion_cateroy_map[cat_id].suggestions.push(suggestion_info.suggestions[i])
      }      
    }

    return (
      <div className="">
        <SectionTitle title="Recommendations" icon_class="fa fa-check"/>

        <div className="p-2">
          {Object.keys(suggestion_cateroy_map).map(function(item_key, idx){
            const item = suggestion_cateroy_map[item_key];
            
            return (
              <div className="accordion" id="accordionPopularSuggestionCard"  key={"_"+idx}>
                <PopularSuggestionCard id={idx} item={item} onEdit={this.props.onEdit} />
              </div>
            )
          }, this)}
        </div>

      </div>
    )
  }
}

class PopularSuggestionCard extends React.Component {

  render (){
    const item = this.props.item;
    const id =  this.props.id;

    if (item === undefined || item === null){
      return '';
    }

    var suggestion_map = {}
    var frequency_order = []
    item.suggestions.map(function(sugg, idx){
      if (suggestion_map[sugg.template.frequency.name] === undefined){
        suggestion_map[sugg.template.frequency.name] = [];
        frequency_order.push(sugg.template.frequency);
      }
      suggestion_map[sugg.template.frequency.name].push(sugg);
      return null;
    })
    frequency_order.sort(function(a, b){return a.seconds_count - b.seconds_count});

    return (
      
      <div className="dark-highlight-1 border">
        <div className="card-header">
          <h5 className="mb-0">
            <a href="/" onClick={(e) => e.preventDefault()} className=" " data-toggle="collapse" data-target={"#collapse"+id} aria-expanded="true">
              <div className="text-size-5">{item.title}</div>
              <div className="">
                <small className="text-size-2 black-text-8">{item.description}</small>
              </div>
            </a>
          </h5>
        </div>

        <div id={"collapse"+id} className="collapse show" data-parent="#accordionPopularSuggestionCard">
          <div className="card-body">
          
          {frequency_order.map(function(frequency, idx){
            return (
              <div className="m-2 p-1 bg-light" key={"_"+idx}>
                <h5 className="my-2 text-muted">{frequency.name}</h5>
                <div className="row flex-row flex-nowrap" style={{"overflowX": "auto"}}>
                  {suggestion_map[frequency.name].map(function(sugg, idx){
                    const tmpl = sugg.template;
                    return(
                      <div key={"_"+idx} className="col-xs m-0 p-0" >
                        <ActivityTemplateCardView template={tmpl} reason_count={sugg.reason} onEdit={this.props.onEdit} />
                      </div>
                      )
                    }, this)}

                </div>
                <hr className="" />
              </div>
            )
          }, this)}

          </div>
        </div>
      </div>
      // </div>
    )
  }
}

class ActivityTemplateCardView extends React.Component {
  state = {
    hover: false,
  }

  toggleHover(){
    this.setState({hover: !this.state.hover})
  }

  render (){
    const tmpl = this.props.template;
    const reason_count = this.props.reason_count;
    const onClick = this.props.onEdit;

    if (tmpl === undefined || tmpl === null){
      return '';
    }

    const btnStyle = this.state.hover? {'color': 'white', 'backgroundColor': tmpl.color}: {'color': tmpl.color, 'borderColor': tmpl.color};

    const star_count = parseInt((parseInt(reason_count) / 600)*5) + 1;
    return (
      <div style={{width: '160px'}}>
        <div className=" m-1 pt-1 pb-3 px-1 white-bg-1 border  text-center">
            
          <div className="text-right">
            <DomainBadge domain={tmpl.domain} />
          </div>
          <h5 className={"text-size-3 text-left ml-2  mt-1 mb-2"}>{tmpl.title}</h5>

          <hr className="my-2" />

          <div className=" black-text-6 text-center d-flex w-100 justify-content-center content-align-center">
            {(tmpl.count === undefined || tmpl.count === null)? (''):(
              <div className="">
                <div className="text-size-2 mx-2">
                  {tmpl.frequency.name}
                </div>
                <div className="text-size-7">
                  {tmpl.count.target}
                </div>
                <div className="text-size-1 mx-2">
                  {" " + ((tmpl.count.target === 1)? tmpl.count.unit.singular: tmpl.count.unit.plural)}
                </div>
              </div>
            )}
            {/* <div className="">{tmpl.frequency.name}</div> */}
          </div>

          {/* <div className="text-muted text-center"><small>{sugg.reason}</small></div> */}
          <div className="my-2 text-muted text-center">
            {[...Array(star_count)].map((e, i) => <span className="" key={i}><i className="fas fa-star mr-1"></i></span>)}
          </div>

          <div className="mt-2">
            <button 
              id={tmpl.xid} 
              className="m-2 btn btn-rounded" 
              type="button" 
              onMouseEnter={this.toggleHover.bind(this)} 
              onMouseLeave={this.toggleHover.bind(this)} 
              style={btnStyle} 
              onClick={onClick}
            >
              <i className="fa fa-edit mr-2"></i>Customize
            </button>
          </div>

        </div>
      </div>
    )
  }
}



export default withRouter(Page);
