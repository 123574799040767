import React from "react";
import { Link } from 'react-router-dom';

export default function AddNewCard(props){

  const q_str = props.q_str || '';
  // const is_mobile = window.innerWidth < 640;

  return (
    <div className="m-1 p-2 light-bl-1-4">
      <Link to={"/create" + (q_str)} className="btn btn-info btn-rounded" data-tip="Add new activity">
        {/* <i className={"fa fa-plus " + ((is_mobile)? ' m-0 ': ' mr-2')}></i>{(is_mobile)? '':'target'} */}
        <i className={"fa fa-plus"}></i>
      </Link>
    </div>
  )
}
