import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import qs from 'query-string';

import LandingLayout from 'component/page/landing';
import {LoaderRipple} from 'component/loader';
import SocialButton from 'component/social_login';

import {saveDemoToken, saveToken, saveLoginMeta, hasStoredToken} from 'utils/authentication';
import {GeneralAPI, handleError} from 'utils/xhr';
import Track from 'utils/tracker';


class LoginSection extends React.Component {

  render (){
    return (
        <div className="login-form bg-light rounded p-2">

          <div className={"avatar "} align="center">
            <span className="fa-stack fa-4x align-items-center">
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-user fa-stack-1x fa-inverse"></i>
            </span>
          </div>

          <div className="my-3 ">
            <h5 className="text-center family-code text-center">Form long lasting habits</h5>
            <h2 className="text-center family-code text-center">Login and track!</h2>
          </div>

          <div className="m-4">
            <div className="text-center my-3">
              {/* <SocialButton
                provider='facebook'
                appId={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_APP_ID}
                onLoginSuccess={this.props.handleFacebokLoginSuccess}
                onLoginFailure={this.props.handleFacebookLoginFailure}
                faicon="fab fa-facebook"
                bg="facebook"
              >
                Login with Facebook
              </SocialButton> */}

              <SocialButton
                provider='google'
                appId={process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_APP_ID}
                onLoginSuccess={this.props.handleGoogleLoginSuccess}
                onLoginFailure={this.props.handleGoogleLoginFailure}
                faicon="fab fa-google"
                bg="googlered text-white"
              >
                Login with Google
              </SocialButton>

              {/* <SocialButton
                provider='linkedin'
                appId={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_APP_ID}
                onLoginSuccess={this.props.handleFacebokLoginSuccess}
                onLoginFailure={this.props.handleFacebookLoginFailure}
                faicon="fab fa-linkedin"
                bg="linkedin"
              >
                Login with LinkedIn
              </SocialButton> */}

            </div>

            {/* <hr className=""/> */}
            <div className="text-center my-3">OR</div>
            {/* <hr className=""/> */}

            {/* <div className="m-2 text-center">
              <Link to="/login/email" className="btn btn-lg btn-block btn-primary"><i className="fa fa-envelope mr-2"></i>Login with E-Mail</Link>
            </div> */}

            {this.props.forceLogin? (
            <div className="">
              {/* <hr className=""/>
              <div className="text-center">DEMO</div>
              <hr className=""/> */}

              {/* <div className="m-2 text-center">
                <button onClick={this.props.forceLogin} className="btn btn-lg btn-block btn-secondary text-white"><i className="fa fa-eye mr-2"></i>View Demo</button>
              </div> */}

              <div className="m-2 text-center">
                <Link to="/public/20000101" className="btn btn-lg btn-block btn-dark text-white"><i className="fa fa-eye mr-2"></i>View Demo</Link>
                <small className="text-muted">No login required to play with the demo</small>
              </div>

            </div>
            ): ''}

            {/* <hr className=""/>
            <div className="text-center">OR</div>
            <hr className=""/>


            <div className="">
              <div className="m-2 text-center">
                <Link to="/public/build" className="btn btn-lg btn-block btn-secondary text-white"><i className="fa fa-bullhorn mr-2"></i>Build public schedule</Link>
              </div>
            </div> */}

            <div className="text-center">
            {(this.props.signin)? (<LoaderRipple />): ('')}
            </div>

            <hr className=""/>

            <small>
              <small>
              By loging in, you agree to the <a href="https://bytebeacon.com/legal/privacy" className="">Privacy Policy</a> and <a href="https://bytebeacon.com/legal/tos" className="">Terms of service</a>
              </small>
            </small>

          </div>
        </div>
    )
  }
}


class LoginPage extends React.Component {

  state = {
    signin: false
  }

  componentDidMount(){
    if (hasStoredToken()){
      this.props.history.push('/sites');
    }
  }

  handleGoogleLoginSuccess = (response) => {
    return this.handleLoginSuccess('google', response);
  }

  handleFacebookLoginSuccess = (response) => {
    return this.handleLoginSuccess('facebook', response);
  }

  handleLoginSuccess = (service, response) => {
    Track.user("login: success_" + service);
    this.setState((prevState, props) =>{
      return {signin: true}
    })
    const access_token = response._token.accessToken;
    const headers = {
      headers: {'Authorization': 'Bearer ' + access_token}
    }
    const url = '/users/auth/' + service;

    GeneralAPI.post(url, {}, headers).then(response => {
      this.setState({
        signin: false
      })
      const success = response.data.meta.success;
      if (success){
        var jwt_token = response.data.data.token;
        saveToken(jwt_token);
        saveLoginMeta(response.data.data.meta);

        Track.user("login: success_token_" + service, response.data.data.meta.user_xid);

        const values = qs.parse(this.props.location.search);
        var destination = values.dest || '/active';
        this.props.history.push(destination);

      } else {
        alert(response.data.data.message);
      }
    }, (error) => {
      Track.user("login: fail_token_" + service);
      handleError(error);
      this.setState({
        signin: false
      })
    });
  }

  handleLoginFailure = (service, response) => {
    Track.user("login fail_" + service);
    console.log(service + ': Byte-Check Failed login attemp or SDK load error: ' + response);
  }

  handleGoogleLoginFailure = (response) => {
    this.handleLoginFailure('google', response);
  }

  handleFacebookLoginFailure = (response) => {
    this.handleLoginFailure('facebook', response);
  }

  forceLogin(){
    saveDemoToken();
    const values = qs.parse(this.props.location.search);
    var destination = values.dest || '/active';
    this.props.history.push(destination);
  }

  render (){
    const is_mobile = window.innerWidth < 640;

    return (
      <LandingLayout id="login-page">

        <div className={"flex-center "+ ((is_mobile)? ' my-5 ': '')}>
          <LoginSection
            handleGoogleLoginSuccess={this.handleGoogleLoginSuccess}
            handleGoogleLoginFailure={this.handleGoogleLoginFailure}
            handleFacebookLoginSuccess={this.handleFacebookLoginSuccess}
            handleFacebookLoginFailure={this.handleFacebookLoginFailure}
            signin={this.state.signin}
            forceLogin={this.forceLogin.bind(this)}
          />
        </div>

      </LandingLayout>
    )
  }
}

export default withRouter(LoginPage);