import React from 'react';
import {Link} from 'react-router-dom';

import Logo from 'component/assets/logo';
import SocialComponent from './../reuse/social';
import { SubscribeForm } from './../reuse/form_subscription';
import { PROFILE } from "./../constant";

import {CopyrightFooter, CompanyComponent, OtherProductsComponent, ReachComponent} from './common';
// import SelfPromo from 'component/content/self_promo';


export function LandingFooter(props){
  const is_mobile = window.innerWidth < 1200;
  return(
    <footer>
      <div  className="p-1 pb-3 bg-dark text-black-9">
        <div className="row m-0">
          <div className="col-sm-5 px-0"><AboutComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><CompanyComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><OtherProductsComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-3 px-0"><ReachComponent is_mobile={is_mobile} /></div>
        </div>
      </div>
      {/* <SelfPromo /> */}
      <CopyrightFooter padding={5} />
    </footer>
  )
}



function AboutComponent(props){
  return (
    <div className="">
      <div className="m-1">        
        <div className="mt-5 mb-2 ">
          <Link to="/" className="navbar-brand text-spacing-2 mr-2">
            {/* <img style={{height: "20px"}} className="hero mx-2" src="/logo.svg" alt="logo svg" /> */}
            <span className="text-primary m-2"><Logo name="logo" size={25} /></span>
            <span className="text-white">Byte</span><span className="text-primary">Track</span>
          </Link>  
        </div>
        <div className=" p-2 family-code text-white">
          <p className="">
            {/* I was overweight and wanted to do something about it. I knew that running a few miles everyday will help, I knew daily workouts will help, but not much was sticking   */}
            An easy way for you to track activities and get a report that motivates you further. 
          </p>
          <p className="">
            This is a powerful tool to build habits that stick with you for the long run.
          </p>
        </div>
        
      </div>

      {/* <hr className="highlight-white-4 "/> */}

      <div className="m-3">
        <SubscribeForm full_width={true} />
      </div>
    </div>
  )
}


export function LoggedInFooter(props){
  return(
    <footer className={"gradient-dark py-2"}>
      
      <div className="col"></div>
      
      <div className="col">
        <div className="text-white fa-pull-left family-code ">
          <div className="uppercase text-spacing-2">
            <Link className="small" to="/">Home</Link>
            <span className="mx-2 text-white">|</span>
            <Link className="small" to="/legal/privacy">Privacy</Link>
            <span className="mx-2 text-white">|</span>
            <Link className="small" to="/legal/tos">Terms</Link>
          </div>
          <div className="small">
            © { new Date().getFullYear() } - <Link className=" uppercase text-spacing-2" to="/">ByteBeacon, Inc</Link> - All rights reserved
          </div>
        </div>
      </div>

      <div className="col"></div>
      
      <div className="col">
        <div className="fa-pull-right">
          <SocialComponent contact={PROFILE.contact} />
        </div>
      </div>

      <div className="col"></div>

    </footer>
  )
}
