import API from 'utils/xhr';


function _getFromStorage(key){
  return localStorage.getItem("BB_BYTE_TRACK_" + key);
}

function _setToStorage(key, data){
  localStorage.setItem("BB_BYTE_TRACK_" + key, data);
}

function _removeFromStorage(key){
  localStorage.removeItem("BB_BYTE_TRACK_" + key);
}


export function hasStoredToken(){
  return !!_getFromStorage("token");
}

export function getStoredToken(){
  return _getFromStorage("token");
}

export function saveToken(token){
  _setToStorage("token", token);
  API.defaults.headers['Authorization'] = 'Bearer ' + token;
}

export function deleteToken(){
  _removeFromStorage('token');
  clearLoginMeta();
}


export function saveLoginMeta(meta){
  _setToStorage("user_xid", meta.user_xid);
  _setToStorage("pilot_enabled", meta.pilot_enabled || false);
}

export function clearLoginMeta(meta){
  _removeFromStorage('user_xid');
  _removeFromStorage('pilot_enabled');
}

export function getUserId(){
  return _getFromStorage("user_xid");
}

export function isPilotAccount(){
  return _getFromStorage("pilot_enabled");
}

export function saveDemoToken(){
  // TODO: move this into the config
  saveToken("DEMO_ACCOUNT");
}

export function isDemoAccount(){
  const token = getStoredToken("token") || '';
  return token.includes('DEMO_ACCOUNT');
}


export function savePublicToken(){
  saveToken("PUBLIC_USER_ID");
}

export function isPublicAccount(){
  const token = getStoredToken("token") || '';
  return token.includes('PUBLIC_USER_ID');
}


export function hasTimezone(){
  return _getFromStorage("timezone_set")  || false;
}

export function setTimezone(){
  _setToStorage("timezone_set", true);
}

