import {
  lifestyle_domain,
  health_domain,
  relationship_domain,
  entrepreneurship_domain,
  empathy_domain,
  // unclassified_domain,
} from './domains';


////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Activity frequency
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const frequency = {
  DAILY: {name: 'daily', },
  WEEKLY: {name: 'weekly', },
  MONTHLY: {name: 'monthly', },
  YEARLY: {name: 'yearly', },
}

const no_frequency = {
  name: 'frequency missing'
}

export function fetch_from_frequency_name(text){
  const calculated_frequency = {
    'daily': frequency.DAILY,
    'weekly': frequency.WEEKLY,
    'monthly': frequency.MONTHLY,
    'yearly': frequency.YEARLY,  
  }[text.replace(/^\s+|\s+$/g, '')]

  return (calculated_frequency === undefined)? no_frequency: calculated_frequency;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Activity template
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const cooking_template = {
  domain: lifestyle_domain,
  xid: "tmpl_act_cook",
  title: "Cook",
  color: lifestyle_domain.color,
  count: {target: 1, unit: {singular: "meal", plural: "meals"}},
  frequency: frequency.WEEKLY,
}

export const workout_template = {
  domain: health_domain,
  xid: "tmpl_act_work",
  title: "Workout",
  color: health_domain.color,
  count: {target: 3, unit: {singular: "time", plural: "times"}},
  frequency: frequency.WEEKLY,
}

export const friend_template = {
  domain: relationship_domain,
  xid: "tmpl_act_frnd",
  title: "Call Friend",
  color: relationship_domain.color,
  count: {target: 1, unit: {singular: "call", plural: "calls"}},
  frequency: frequency.WEEKLY,
}

export const water_template = {
  domain: health_domain,
  xid: "tmpl_act_water",
  title: "Water",
  color: health_domain.color,
  count: {target: 8, unit: {singular: "glass", plural: "glasses"}},
  frequency: frequency.DAILY,
}

export const blog_template = {
  domain: entrepreneurship_domain,
  xid: "tmpl_act_blog",
  title: "Blogs",
  color: entrepreneurship_domain.color,
  count: {target: 1, unit: {singular: "article", plural: "articles"}},
  frequency: frequency.MONTHLY,
}

export const vacation_template = {
  domain: relationship_domain,
  xid: "tmpl_act_vacation",
  title: "Vacations",
  color: relationship_domain.color,
  count: {target: 2, unit: {singular: "time", plural: "times"}},
  frequency: frequency.YEARLY,
}

export const good_deed_template = {
  domain: empathy_domain,
  xid: "tmpl_act_deed",
  title: "Good Deed",
  color: empathy_domain.color,
  count: {target: 3, unit: {singular: "time", plural: "times"}},
  frequency: frequency.MONTHLY,
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Activity Suggestions
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const suggestion_info_template_mock = {
  categories: [
    {
      xid: "ctgr_loc",
      title: "Location", 
      description: "What people near you are currently tracking",
    },
    {
      xid: "ctgr_act",
      title: "Your activities", 
      description: "People who are tracking activities similar to yours",
    },
    {
      xid: "ctgr_weak_targets",
      title: "Your weak targets", 
      description: "Use these targets to get back on track that is imporatant to you",
    },  
  ],

  suggestions: [
    {category_xids: ["ctgr_loc", "ctgr_weak_targets"], template: cooking_template, count: {tracking: 123}},
    {category_xids: ["ctgr_loc"], template: workout_template, count: {tracking: 512}},
    {category_xids: ["ctgr_loc", "ctgr_act"], template: water_template, count: {tracking: 37}},
    {category_xids: ["ctgr_loc", "ctgr_weak_targets"], template: good_deed_template, count: {tracking: 67}},
    {category_xids: ["ctgr_act"], template: blog_template, count: {tracking: 52}},
    {category_xids: ["ctgr_act"], template: vacation_template, count: {tracking: 29}},
  ]
}

