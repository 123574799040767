import React from "react";


export default function SocialComponent(props){
  const contact = props.contact;
  const background = props.background || ' ';
  const enlarge = (props.enlarge)? ' text-size-9 ': '';
  const style = (props.color)? {color: props.color}: {};

  return (
    <div className="">
      {contact.instagram ? <a style={style} aria-label="instagram" href={contact.instagram} className={"mx-3 " + background + enlarge}><i className={"fab fa-instagram" + enlarge}></i></a>: null }
      {contact.twitter ? <a style={style} aria-label="twitter" href={contact.twitter} className={"mx-3 " + background + enlarge}><i className={"fab fa-twitter" + enlarge}></i></a>: null }
      {contact.linkedin ? <a style={style} aria-label="linkedin" href={contact.linkedin} className={"mx-3 " + background + enlarge}><i className={"fab fa-linkedin-in" + enlarge}></i></a>: null }
      {contact.facebook ? <a style={style} aria-label="facebook" href={contact.facebook} className={"mx-3 " + background + enlarge}><i className={"fab fa-facebook-f" + enlarge}></i></a>: null }
      {contact.github ? <a style={style} aria-label="github" href={contact.github} className={"mx-3 " + background + enlarge}><i className={"fab fa-github" + enlarge}></i></a>: null }
      {contact.quora ? <a style={style} aria-label="quora" href={contact.quora} className={"mx-3 " + background + enlarge}><i className={"fab fa-quora" + enlarge}></i></a>: null }
      {contact.docker ? <a style={style} aria-label="docker" href={contact.docker} className={"mx-3 " + background + enlarge}><i className={"fab fa-docker" + enlarge}></i></a>: null }
    </div>
  )
}
