import {fetch_from_frequency_name} from './template';
// import {unclassified_domain} from './domains';


export function build_count_for_template(count_str){
  var calculated_count = null;
  const unit_str = count_str.replace(/^\s+|\s+$/g, '');

  if (unit_str === 'once'){
    calculated_count ={
      target: 1, 
      unit: {
        singular: "time", 
        plural: "times",
      }
    };

  } else if (unit_str === 'twice'){
    calculated_count ={
      target: 2, 
      unit: {
        singular: "time", 
        plural: "times",
      }
    };

  } else {
    const unit_sections = unit_str.split(" ");
    const target = parseInt(unit_sections[0]) || '';
    const unit = unit_str.substring(unit_sections[0].length);

    calculated_count ={
      target: target, 
      unit: {
        singular: unit.replace(/^\s+|\s+$/g, ''), 
        plural: unit.replace(/^\s+|\s+$/g, ''),
      }
    };
  }

  return calculated_count;
}


export function build_title_for_template(raw_title){
  return raw_title.charAt(0).toUpperCase() + raw_title.slice(1);
}

export function build_frequency_for_template(frequency_str){
  return fetch_from_frequency_name(frequency_str)
}


export function generate_new_activity_template(text){

  const sections = text.split(',');

  const calculated_title = (sections.length >= 1)? build_title_for_template(sections[0]): '';
  const calculated_count = (sections.length >= 2)? build_count_for_template(sections[1]): null;
  const calculated_frequency = (sections.length >= 3)? build_frequency_for_template(sections[2]): '';

  var activity_template = {
    domain_xid: null,
    title: calculated_title,
    count: calculated_count,
    frequency: calculated_frequency,
  }

  return activity_template;
}

export function generate_logs(count, gap, target){
  const dates = generate_past_dates(count, gap)

  var logs = [];
  for (var i=0; i<count; i++){
    logs.push({
      slot: dates[i], 
      reached: Math.floor(Math.random() * ((target>1)? target+2: target)), 
      target: target,
    })
  }
  return logs;
}

function generate_past_dates(count, gap){
  
  const today = new Date();

  var dates = [];
  for (var i=0; i<count; i++){

    const curr_date = new Date(today)
    curr_date.setDate(today.getDate() - (i * gap))
    
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }) ;
    const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(curr_date);

    if (gap === 1){
      dates.push(`${day}-${month}`)
    } else if (gap === 7){
      dates.push(`${day}-${month}`)
    } else if (gap === 30){
      dates.push(`${month}-${year}`)
    } else if (gap === 365){
      dates.push(`${year}`)
    }
  }
  return dates;
}