import axios from 'axios';
import qs from 'query-string';

import {getStoredToken, deleteToken} from 'utils/authentication';

export let GeneralAPI = generateLoginAPIInstance(getStoredToken());
function generateLoginAPIInstance(jwt_token){
  
  // TODO cleanup divshow's login flow for the long run
  // using divshow's login flow for now

  let instance = axios.create({
    // baseURL: process.env.REACT_APP_API_DOMAIN + '/api/v1',
    baseURL: process.env.REACT_APP_API_DOMAIN + '/api/v1',
    headers: {
      'Authorization': 'Bearer ' + jwt_token
    }
  });
  return instance;
}


export let API = generateAPIInstance(getStoredToken());
function generateAPIInstance(jwt_token){
  
  let instance = axios.create({
    // baseURL: process.env.REACT_APP_API_DOMAIN + '/api/v1',
    baseURL: process.env.REACT_APP_API_DOMAIN + '/api/v1/bytetrack',
    headers: {
      'Authorization': 'Bearer ' + jwt_token
    }
  });
  return instance;
}


export function handleError(error, history, from_location, alert_error){
  if (error.response === undefined){
    console.log("Something went wrong! Error response came back undefined.");
    console.log(error);
    return;
  }

  const status_code = error.response.status;
  if (status_code === 401){
    deleteToken();
    if (from_location !== undefined){
      const query = { dest: from_location};
      const searchString = qs.stringify(query);
      const login_url = "/login?" + searchString;
      history.push(login_url);
    } else {
      history.push('/login');
    }

  } else if (status_code === 500){
    console.log(error.response);
    // Track.exception(true, error.response.data.data.message);
    if (alert_error === undefined || alert_error){
      alert(error.response.data.data.message);
    }
    
  } else {
    console.log(error.response);
    // Track.exception(false, error.response.data.data.message);
    alert(error.response.data.data.message);
    // window.location.reload();
  }
}

export default API;
